import React, {
  useContext,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  flexRender,
} from '@tanstack/react-table';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './filtering.css';

import FilterDisplay from './FilterDisplay';
import GlobalFilter from './GlobalFilter';
import PaginationControl from './PaginationControl';
import TableBody from './TableBody';
import TableControls from './TableControls';
import TableHeader from './TableHeader';

import { CommonCollections } from '../../../../App';
import useFetchMarkdown from '../../../hooks/useFetchMarkdown';
import useFilterInstance from '../../../hooks/useFilterInstance';
import useColumnVisibility from '../../../hooks/useColumnVisibility';
import useLocalStorage from '../../../hooks/useLocalStorage';
import useTableFilters from '../../../hooks/useTableFilters';
import useDebounce from '../../../hooks/useDebounce';

import formatSectionalCommitteeName from '../../../../utils/formatSectionalCommitteeName';

import { useLoggedInUserContextProvider } from '../../../../context/LoggedInUserContext';
import { accentInsensitiveGlobalFilter } from '../../../../utils/tableFilters';

import Markdown from '../../Forms/Elements/Markdown';

const FilteringTable = ({
  columns,
  data = [],
  markDownName = '',
  componentName = 'defaultTable',
  urlString = '',
  buttonText = '',
  filter = true,
  title = 'Table Filtering',
  filterInstance = null,
}) => {
  const { userDetails } = useLoggedInUserContextProvider();
  const { setNominationId } = useContext(CommonCollections);
  const { bodyText } = useFetchMarkdown(markDownName);

  const [isSectionalCommitteeFormatted, setIsSectionalCommitteeFormatted] =
    useLocalStorage(`${componentName}IsSectionalCommitteeFormatted`, false);
  const [pageSize, setPageSize] = useLocalStorage(
    `${componentName}PageSize`,
    10,
  );
  const [pageIndex, setPageIndex] = useLocalStorage(
    `${componentName}PageIndex`,
    0,
  );
  const [savedFilters, setSavedFilters] = useLocalStorage(
    `${componentName}Filters`,
    [],
  );
  const [resetPage, setResetPage] = useState(false);

  const [globalFilter, setGlobalFilter] = useState('');
  const debouncedGlobalFilter = useDebounce(globalFilter, 300);

  const { columnVisibility, toggleColumnVisibility } = useColumnVisibility(
    columns,
    componentName,
    null,
  );

  const isAdmin =
    userDetails?.roles?.includes('super-admin') ||
    userDetails?.roles?.includes('admin');

  const memoizedData = useMemo(() => data.map((item) => ({ ...item })), [data]);

  const table = useReactTable({
    data: memoizedData,
    columns,
    state: {
      pagination: {
        pageIndex,
        pageSize,
      },
      columnVisibility,
      globalFilter: debouncedGlobalFilter,
      columnFilters: savedFilters,
    },
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    filterFns: {
      accentInsensitive: accentInsensitiveGlobalFilter,
    },
    globalFilterFn: 'accentInsensitive',
    manualPagination: false,
    onColumnFiltersChange: setSavedFilters,
  });

  const prevFiltersRef = useRef(savedFilters);
  const debouncedColumnFilters = useDebounce(
    table.getState().columnFilters,
    300,
  );

  const tableRef = useRef(null);
  tableRef.current = table;

  useEffect(() => {
    table.setPageIndex(pageIndex);
    table.setPageSize(pageSize);
  }, [pageIndex, pageSize, table]);

  useEffect(() => {
    table.setColumnVisibility(columnVisibility);
  }, [columnVisibility, table]);

  useFilterInstance(
    filterInstance,
    useMemo(() => tableRef.current, []),
    componentName,
  );

  const memoizedSetSavedFilters = useCallback(setSavedFilters, [
    setSavedFilters,
  ]);
  const { resetFilters: resetTableFilters } = useTableFilters(
    memoizedSetSavedFilters,
    setGlobalFilter,
    table,
  );

  const resetFilters = useCallback(() => {
    setGlobalFilter('');
    setSavedFilters([]);
    resetTableFilters();
    table.resetColumnFilters();
    table.resetGlobalFilter();
    table.setPageIndex(0);
  }, [table, setSavedFilters, resetTableFilters]);

  useEffect(() => {
    if (debouncedColumnFilters) {
      const prevFiltersJSON = JSON.stringify(prevFiltersRef.current || []);
      const debouncedFiltersJSON = JSON.stringify(debouncedColumnFilters);

      if (prevFiltersJSON !== debouncedFiltersJSON) {
        setSavedFilters(debouncedColumnFilters);
        prevFiltersRef.current = debouncedColumnFilters;

        if (
          table.getState().pagination.pageIndex !== 0 &&
          debouncedColumnFilters.length > 0
        ) {
          table.setPageIndex(0);
          setPageIndex(0);
        }
      }
    }
  }, [debouncedColumnFilters, setSavedFilters, table, setPageIndex]);

  useEffect(() => {
    if (resetPage) {
      table.setPageIndex(0);
      setResetPage(false);
    }
  }, [resetPage, table]);

  const updatePageIndex = useCallback(
    (newPageIndex) => {
      setPageIndex(newPageIndex);
      table.setPageIndex(newPageIndex);
    },
    [setPageIndex, table],
  );

  const updatePageSize = useCallback(
    (newPageSize) => {
      setPageSize(newPageSize);
      table.setPageSize(newPageSize);
    },
    [setPageSize, table],
  );

  const { columnFilters } = table.getState();
  const filterString = useMemo(() => {
    const filters = columnFilters || [];
    return filters
      .map((filterStringInstance) => filterStringInstance.id)
      .join(', ');
  }, [columnFilters]);

  return (
    <>
      {markDownName && bodyText && (
        <div className="card">
          <div className="card-body">
            <Markdown className="col-xl-12 col-lg-12 mt-3" text={bodyText} />
          </div>
        </div>
      )}

      <div className="card">
        <div className="card-header">
          <h4 className="card-title">{title}</h4>
          {urlString && (
            <Link to={`/${urlString}`} onClick={() => setNominationId(null)}>
              <button type="button" className="btn btn-success">
                <span className="btn-icon-start text-info">
                  <i className="fa fa-plus color-info" />
                </span>
                {buttonText}
              </button>
            </Link>
          )}
        </div>

        <div className="card-body">
          <FilterDisplay idsString={filterString} resetFilters={resetFilters} />

          <div className="row mb-xl-3">
            {filter && (
              <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            )}

            {isAdmin && (
              <TableControls
                rows={table.getFilteredRowModel().rows}
                columns={columns}
                pageSize={pageSize}
                setPageSize={updatePageSize}
                setTablePageSize={updatePageSize}
                columnVisibility={columnVisibility}
                handleColumnToggle={toggleColumnVisibility}
                filename={`${componentName}_data.csv`}
                isSectionalCommitteeFormatted={isSectionalCommitteeFormatted}
                setIsSectionalCommitteeFormatted={
                  setIsSectionalCommitteeFormatted
                }
              />
            )}
          </div>

          <div className="table-responsive">
            <table className="table table-striped table-bordered dataTable display">
              <TableHeader
                headerGroups={table.getHeaderGroups()}
                filter={filter}
                flexRender={flexRender}
              />
              <TableBody
                page={table.getRowModel().rows}
                flexRender={flexRender}
                formatSectionalCommitteeName={formatSectionalCommitteeName}
                isSectionalCommitteeFormatted={isSectionalCommitteeFormatted}
              />
            </table>
          </div>
          <div className="mt-5">
            {table.getPageCount() > 1 && (
              <PaginationControl
                pageIndex={table.getState().pagination.pageIndex}
                pageOptions={Array.from(
                  { length: table.getPageCount() },
                  (_, i) => i,
                )}
                gotoPage={updatePageIndex}
                nextPage={() =>
                  updatePageIndex(table.getState().pagination.pageIndex + 1)
                }
                previousPage={() =>
                  updatePageIndex(table.getState().pagination.pageIndex - 1)
                }
                canNextPage={table.getCanNextPage()}
                canPreviousPage={table.getCanPreviousPage()}
                pageCount={table.getPageCount()}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

FilteringTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  componentName: PropTypes.string,
  markDownName: PropTypes.string,
  urlString: PropTypes.string,
  buttonText: PropTypes.string,
  filter: PropTypes.bool,
  title: PropTypes.string,
  filterInstance: PropTypes.object,
};

export default FilteringTable;
