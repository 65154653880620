import { useCallback, useEffect } from 'react';
import useLocalStorage from './useLocalStorage';

const useColumnVisibility = (columns, componentName, toggleHideColumn) => {
  const [columnVisibility, setColumnVisibility] = useLocalStorage(
    `${componentName}ColumnVisibility`,
    columns.reduce((acc, col) => {
      acc[col.accessor] = true;
      return acc;
    }, {}),
  );

  const toggleColumnVisibility = useCallback(
    (accessor) => {
      setColumnVisibility((prev) => ({
        ...prev,
        [accessor]: !prev[accessor],
      }));
    },
    [setColumnVisibility],
  );

  const getHiddenColumns = useCallback(() => {
    const alwaysVisibleColumns = ['candidate', 'actions'];

    return columns
      .filter(
        (col) =>
          !alwaysVisibleColumns.includes(col.accessor) &&
          columnVisibility[col.accessor] === false,
      )
      .map((col) => col.accessor);
  }, [columns, columnVisibility]);

  useEffect(() => {
    if (toggleHideColumn) {
      columns.forEach((column) => {
        if (column.accessor) {
          toggleHideColumn(column.accessor, !columnVisibility[column.accessor]);
        }
      });
    }
  }, [columnVisibility, toggleHideColumn, columns]);

  return {
    columnVisibility,
    toggleColumnVisibility,
    getHiddenColumns,
  };
};

export default useColumnVisibility;
