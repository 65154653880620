import React, { useContext } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ReportDataContext } from '../../../../context/ReportDataContext';
import { getNominationRoundYear } from '../../../../utils/getNominationRoundYear';

const ApexBar3 = () => {
  const data = useContext(ReportDataContext);
  const { nominationsContinuing, nominationsNew } = data;

  const roundYear = getNominationRoundYear();

  const series = [
    {
      name: 'Continuing',
      data: [13, 11, 10, 10, nominationsContinuing],
    },
    {
      name: 'New',
      data: [10, 20, 50, 43, nominationsNew],
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: true,
      fontSize: '12px',
      fontWeight: 300,
      labels: {
        colors: 'black',
      },
      position: 'bottom',
      horizontalAlign: 'center',
      markers: {
        width: 19,
        height: 19,
        strokeWidth: 0,
        radius: 19,
        strokeColor: '#fff',
        fillColors: ['#00a2ff', '#99daff'],
        offsetX: 0,
        offsetY: 0,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: '#3e4954',
          fontSize: '14px',
          fontFamily: 'Poppins',
          fontWeight: 100,
        },
      },
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['2021', '2022', '2023', '2024', roundYear],
    },
    fill: {
      colors: ['#00a2ff', '#99daff'],
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val} nominations`;
        },
      },
    },
  };

  return (
    <div id="chart" className="line-chart-style bar-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={300}
      />
    </div>
  );
};

export default ApexBar3;
