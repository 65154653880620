import { useCallback } from 'react';

const useTableFilters = (setFilters, setGlobalFilter, tableInstance) => {
  const resetFilters = useCallback(() => {
    setGlobalFilter('');
    tableInstance.resetColumnFilters();
    setFilters([]);
  }, [setGlobalFilter, setFilters, tableInstance]);

  return {
    resetFilters,
  };
};

export default useTableFilters;
