import React, { useEffect, useState, useContext } from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Select from 'react-select';
import Switch from 'react-switch';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { axiosPut } from '../../utils/axiosPut';
import { windowScrollToTop } from '../../utils/windowScrollToTop';

import PageTitle from '../layouts/PageTitle';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { CommonCollections } from '../../App';
import useNavigationPrompt from '../hooks/useNavigationPrompt';

const ChairCommittee = () => {
  const { activeFellowsArray, sectionalCommittees, usersArray } =
    useStaticCollections();
  const { chairCommitteeArray } = useAdminCollections();
  const [notification, setNotification] = useState(null);
  const [toggleStates, setToggleStates] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');

  const { control, setValue, handleSubmit } = useForm();
  const { setIsFormSubmitted } = useContext(CommonCollections);

  const updatedSectionalCommittees = {
    ...sectionalCommittees,
    14: 'CMSE: Corresponding Member and Special Election Committee',
  };

  useEffect(() => {
    (async () => {
      try {
        const initialToggleStates = {};
        chairCommitteeArray.forEach((item) => {
          initialToggleStates[item?.id] =
            item?.attributes?.isShortlistingFinalised;
        });
        setToggleStates(initialToggleStates);

        chairCommitteeArray.forEach(({ id, attributes }) => {
          if (attributes.committeeChair.data) {
            const selectedFellow = activeFellowsArray.find(
              (fellow) =>
                fellow.id ===
                parseInt(attributes.committeeChair?.data?.attributes.fellowId),
            );
            if (selectedFellow) {
              setValue(`committee-chair-${id}`, {
                value: attributes.committeeChair.data.id,
                label: selectedFellow.attributes.label,
              });
            }
          }

          const committeeMembers = [];
          attributes.committeeMembers.data.forEach((cm) => {
            const selectedFellow = activeFellowsArray.find(
              (fellow) => fellow.id === parseInt(cm.attributes.fellowId),
            );
            if (selectedFellow) {
              const committeeMember = {
                value: cm.id,
                label: selectedFellow.attributes.label,
              };
              committeeMembers.push(committeeMember);
            }
            if (cm.attributes.nonAasRefereeId !== null) {
              const committeeMember = {
                value: cm.id,
                label: cm.attributes.username,
              };
              committeeMembers.push(committeeMember);
            }
          });

          setValue(`committee-member-${id}`, committeeMembers);
        });
      } catch (error) {
        console.error('Error in ChairCommittee:', error);
      }
    })();
  }, [chairCommitteeArray, setValue, activeFellowsArray]);

  const onToggleChange = (id) => {
    setToggleStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
    setIsDirty(true);
  };

  useEffect(() => {
    setIsDirty(false);
  }, [chairCommitteeArray]);

  useEffect(() => {
    if (isDirty) {
      setNotificationTitle('Unsaved Changes');
      setNotificationMessage(
        'To save changes, you are required to press the submit button at the bottom of this page.',
      );
    } else {
      setNotificationTitle('');
      setNotificationMessage('');
    }
  }, [isDirty]);

  useNavigationPrompt(
    isDirty,
    'You have unsaved changes. Are you sure you want to leave this page?',
  );

  const onSubmit = async (formData, e) => {
    e.preventDefault();
    try {
      for (const [id, selectedOption] of Object.entries(formData)) {
        const rowId = id.match(/\d+/)[0];
        if (id.includes('committee-chair')) {
          if (selectedOption) {
            await axiosPut(`update-committee/${rowId}`, {
              data: {
                committeeChair: { id: selectedOption.value },
              },
            });
          }
        }
        if (id.includes('committee-member')) {
          if (selectedOption) {
            const userIds = selectedOption.map((option) => ({
              id: option.value,
            }));

            await axiosPut(`update-committee/${rowId}`, {
              data: {
                committeeMembers: userIds,
              },
            });
          }
        }
      }

      for (const [committeeId, isShortlistingFinalised] of Object.entries(
        toggleStates,
      )) {
        await axiosPut(`update-committee/${committeeId}`, {
          data: { isShortlistingFinalised: isShortlistingFinalised ?? false },
        });
      }

      setIsFormSubmitted(true);
      setNotification('Updated');
      setTimeout(() => {
        setNotification('');
      }, 5000);
      windowScrollToTop();
    } catch (error) {
      console.error('Error updating committees:', error);
    }
    setIsDirty(false);
  };

  const selectOptions = activeFellowsArray.map((item) => {
    if (item.id) {
      const user = usersArray.find((users) => users.fellowId === item.id);
      if (user) {
        return {
          value: user.id,
          label: item.attributes.label,
        };
      }
    }
    return null;
  });

  const handleFieldChange = (selectedOption, fieldName) => {
    setValue(fieldName, selectedOption);
    setIsDirty(true);
  };

  return (
    <>
      <PageTitle
        activeMenu="Committees"
        motherMenu="Home"
        notificationMessage={notificationMessage || notification}
        notificationTitle={notificationTitle}
      />
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Committees</h4>
        </div>
        <div className="card-body text-left">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              {Object.entries(updatedSectionalCommittees).map(
                ([id, label], index) => (
                  <React.Fragment key={`committee-${id}`}>
                    {index > 0 && <hr className="my-4" />}
                    <Col xs={12}>
                      <h5 className="mb-xl-4 d-flex justify-content-between align-items-center">
                        {label}
                        <div className="d-flex align-items-center">
                          <span className="me-2 fw-normal">
                            Shortlisting finalised
                          </span>
                          <div
                            data-tooltip-id={`tooltip-${id}`}
                            data-tooltip-content={`${
                              toggleStates[id]
                                ? 'Reminders will be sent to referees of shortlisted candidates only'
                                : 'Reminders will be sent to referees of all candidates'
                            }`}
                          >
                            <Switch
                              onChange={() => onToggleChange(id)}
                              checked={toggleStates[id] || false}
                              onColor="#4cd964"
                              offColor="#e5e5ea"
                              handleDiameter={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              height={24}
                              width={48}
                            />
                          </div>
                          <Tooltip id={`tooltip-${id}`} place="top" />
                        </div>
                      </h5>
                    </Col>
                    <Col key={`chair-${id}`} xs={12} md={6}>
                      <div className="mb-xl-5">
                        <label
                          htmlFor={`committee-chair-${id}`}
                          className="form-label"
                        >
                          Chair
                        </label>
                        <Controller
                          name={`committee-chair-${id}`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={selectOptions}
                              onChange={(selectedOption) => {
                                handleFieldChange(
                                  selectedOption,
                                  `committee-chair-${id}`,
                                );
                              }}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  height: `3rem`,
                                  borderRadius: '0.5rem',
                                  borderColor: '#B3B3B3',
                                }),
                              }}
                              className="mt-1"
                            />
                          )}
                        />
                      </div>
                    </Col>
                    <Col key={`member-${id}`} xs={12} md={6}>
                      <div className="mb-xl-5">
                        <label
                          htmlFor={`committee-member-${id}`}
                          className="form-label"
                        >
                          Member
                        </label>
                        <Controller
                          name={`committee-member-${id}`}
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              options={selectOptions}
                              onChange={(selectedOption) => {
                                handleFieldChange(
                                  selectedOption,
                                  `committee-member-${id}`,
                                );
                              }}
                              styles={{
                                control: (baseStyles) => ({
                                  ...baseStyles,
                                  borderRadius: '0.5rem',
                                  borderColor: '#B3B3B3',
                                }),
                              }}
                              isMulti
                              className="mt-1"
                            />
                          )}
                        />
                      </div>
                    </Col>
                  </React.Fragment>
                ),
              )}
            </div>
            <div className="text-end">
              <Button variant="success" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default ChairCommittee;
