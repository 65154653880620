import axios from 'axios';
import swal from 'sweetalert2';
import { strapiApiUrl } from '../utils/constants';
import { Logout } from '../store/actions/AuthActions';

export function signUp(email, password) {
  const postData = {
    email,
    password,
    returnSecureToken: true,
  };
  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData,
  );
}

export function sendMail(email) {
  const postData = {
    email,
  };
  return axios.post(`${strapiApiUrl}passwordless/send-link`, postData);
}

export function login(Token) {
  const params = {
    loginToken: Token,
  };

  return axios.get(`${strapiApiUrl}jwt-get`, {
    params,
    withCredentials: true,
  });
}

export function formatError(errorResponse) {
  switch (errorResponse.error.message) {
    case 'EMAIL_EXISTS':
      // return 'Email already exists';
      swal('Oops', 'Email already exists', 'error');
      break;
    case 'EMAIL_NOT_FOUND':
      // return 'Email not found';
      swal('Oops', 'Email not found', 'error', { button: 'Try Again!' });
      break;
    case 'INVALID_PASSWORD':
      // return 'Invalid Password';
      swal('Oops', 'Invalid Password', 'error', { button: 'Try Again!' });
      break;
    case 'USER_DISABLED':
      return 'User Disabled';

    default:
      return '';
  }
}

export function saveUserDetails(userDetails) {
  userDetails.expireDate = new Date(new Date().getTime() + 600 * 100000);
}

export function runLogoutTimer(dispatch, timer, navigate) {
  setTimeout(() => {
    dispatch(Logout(navigate));
  }, timer);
}

export function checkAutoLogin(dispatch, navigate, userDetails) {
  if (!userDetails) {
    dispatch(Logout(navigate));
  }
}
