import React from 'react';
import PropTypes from 'prop-types';
import TableRow from './TableRow';

const TableBody = ({
  page,
  flexRender,
  formatSectionalCommitteeName = null,
  isSectionalCommitteeFormatted = null,
}) => (
  <tbody>
    {page.map((row) => (
      <TableRow
        key={row.id}
        row={row}
        flexRender={flexRender}
        formatSectionalCommitteeName={formatSectionalCommitteeName}
        isSectionalCommitteeFormatted={isSectionalCommitteeFormatted}
      />
    ))}
  </tbody>
);

TableBody.propTypes = {
  page: PropTypes.array.isRequired,
  flexRender: PropTypes.func.isRequired,
  formatSectionalCommitteeName: PropTypes.func,
  isSectionalCommitteeFormatted: PropTypes.bool,
};

export default TableBody;
