import { NavLink } from 'react-router-dom';

export const MenuList = (handleMailto) => [
  // Dashboard
  {
    title: 'Reports',
    classChange: 'mm-collapse',
    iconStyle: 'bi bi-grid',
    content: [
      {
        title: 'Nominations',
        to: 'nomination-reports',
        className: 'dropdown-item',
      },
      {
        title: 'Short-listed Candidates',
        to: 'short-listed-candidates',
        className: 'dropdown-item',
      },
      {
        title: 'Corresponding Membership and Special Election',
        to: 'corresponding-and-special-elections',
        className: 'dropdown-item',
      },
      {
        title: 'Elected Candidates',
        to: 'elected-candidates',
        className: 'dropdown-item',
      },
    ],
    access: ['super-admin', 'admin'],
  },

  // Nominations
  {
    title: 'Nominations',
    iconStyle: 'bi bi-list',
    to: 'nominations',
    access: ['fellow', 'super-admin'],
  },

  // Filtering table
  {
    title: 'My References',
    iconStyle: 'bi bi-journals',
    to: 'referee',
    access: ['referee', 'super-admin'],
  },

  // Admin menu items
  {
    title: 'Nomination',
    iconStyle: 'bi bi-list-stars',
    to: 'nomination-administration',
    access: ['admin', 'super-admin'],
  },
  {
    title: 'Referee',
    iconStyle: 'bi bi-journal-medical',
    to: 'referee-administration',
    access: ['admin', 'super-admin'],
  },
  {
    title: 'Referee Archive',
    iconStyle: 'bi bi-archive',
    to: 'referee-archive',
    access: ['admin', 'super-admin'],
  },
  {
    title: 'Shortlisting',
    iconStyle: 'bi bi-star-fill',
    to: 'nomination-shortlisting',
    access: ['admin', 'committee-chair', 'committee-member', 'super-admin'],
  },
  {
    title: 'Assessor',
    iconStyle: 'bi bi-person-plus-fill',
    to: 'assessor-administration',
    access: ['admin', 'committee-chair', 'super-admin'],
  },
  {
    title: 'Elect Candidates',
    iconStyle: 'bi bi-check',
    to: 'elect-candidate',
    access: ['admin', 'super-admin'],
  },

  // Secretary
  {
    title: 'Nomination - Secretary',
    iconStyle: 'bi bi-list-ol',
    to: 'nomination-secretary',
    access: ['secretary'],
  },
  {
    title: 'Referee - Secretary',
    iconStyle: 'bi bi-journal-text',
    to: 'referee-report-secretary',
    access: ['secretary'],
  },

  // Filtering table
  {
    title: 'Administration',
    classChange: 'mm-collapse',
    iconStyle: 'bi bi-lock',
    content: [
      {
        title: 'Extension Requests',
        to: 'referee-report-extension-requests',
      },
      {
        title: 'Committees',
        to: 'committees',
      },
      {
        title: 'Admin Actions',
        to: 'admin-actions',
      },
    ],
    access: ['admin', 'super-admin'],
  },
  {
    title: 'Secretary Actions',
    iconStyle: 'bi bi-download',
    to: 'secretary-actions',
    access: ['secretary'],
  },

  // Support and Help
  {
    title: 'Support and Help',
    iconStyle: 'bi bi-question-circle',
    content: [
      {
        title: 'User Guide',
        custom: true,
        render: () => (
          <a
            href="https://www.science.org.au/files/userfiles/fellowship/documents/FNS_2.0_Proposer_and_Referee_User_Guide.pdf"
            target="_blank"
            className="dropdown-item ai-icon no-hover"
            rel="noreferrer"
          >
            <span className="ms-2">User Guide</span>
          </a>
        ),
      },
      {
        title: 'Need Help?',
        custom: true,
        render: () => (
          <NavLink
            to="#"
            role="button"
            tabIndex="0"
            aria-label="Contact us via email"
            onClick={(e) => {
              e.preventDefault(); // Prevent navigation
              handleMailto('ict@science.org.au');
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleMailto('ict@science.org.au');
              }
            }}
          >
            <span className="ms-2">Contact us</span>
          </NavLink>
        ),
      },
    ],
    access: [
      'admin',
      'committee-chair',
      'committee-member',
      'secretary',
      'referee',
      'fellow',
      'super-admin',
    ],
  },
];
