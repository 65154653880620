import React, { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';
import { axiosPost } from '../../utils/axiosPost';
import { axiosGet } from '../../utils/axiosGet';
import { axiosPut } from '../../utils/axiosPut';
import { axiosDelete } from '../../utils/axiosDelete';

const PreNominationSteps = () => {
  const {
    sectionalCommittees,
    sectionalCommitteeHistoryArray,
    nominationRefereeHistoryArray,
  } = useStaticCollections();

  const { nominationStateArray, candidateStateArray, candidates } =
    useAdminCollections();

  const getNomination = async () =>
    axiosGet('nominations').then((response) => response.data.data);

  const getRefereeReports = async () => {
    const refereeReportArray = await axiosGet(
      `referee-reports?populate[report][populate][0]=report&pagination[limit]=6000`,
    ).then((response) => response.data.data);
    console.log(refereeReportArray);
    return refereeReportArray;
  };
  const getNominationDataToExpire = async () => {
    const filteredNominationState = nominationStateArray.filter(
      (nomState) => nomState.attributes.roundYear >= 5,
    );
    const nominations = await getNomination();
    return nominations.filter((nomination) =>
      filteredNominationState.some(
        (nomState) =>
          nomination.id === nomState.attributes.nominationId &&
          (nomination.attributes.nominationStatusTypeId === 2 ||
            nomination.attributes.nominationStatusTypeId === 3),
      ),
    );
  };

  const getRefereesToArchive = async () => {
    const nominations = await getNomination();
    const filteredNominations = nominations.filter(
      (nom) =>
        nom.attributes.nominationStatusTypeId === 4 ||
        nom.attributes.nominationStatusTypeId === 5,
    );
    const refereeReportArray = await getRefereeReports();
    return refereeReportArray.filter((refereeReport) =>
      filteredNominations.some(
        (nomination) =>
          refereeReport.attributes.nominationId === nomination.id &&
          refereeReport.attributes.refereeArchived !== true,
      ),
    );
  };

  const getRelevantNominations = async () => {
    const nominations = await getNomination();
    return nominations.filter(
      (nom) =>
        nom.attributes.nominationStatusTypeId === 2 ||
        nom.attributes.nominationStatusTypeId === 3,
    );
  };

  const getRelevantNominationAndDrafts = async () => {
    const nominations = await getNomination();
    return nominations.filter(
      (nom) =>
        nom.attributes.nominationStatusTypeId === 1 ||
        nom.attributes.nominationStatusTypeId === 2 ||
        nom.attributes.nominationStatusTypeId === 3,
    );
  };

  const getLatestNominationState = (nominationId) => {
    const flattenedNominationState = nominationStateArray.flat();
    const filteredNominationState = flattenedNominationState.filter(
      (nomState) => nomState.attributes.nominationId === nominationId,
    );
    return filteredNominationState.reduce(
      (latest, current) =>
        current.attributes.roundYear > latest.attributes.roundYear
          ? current
          : latest,
      filteredNominationState[0],
    );
  };

  const deleteFile = async (fileId) => {
    console.log(fileId);
    await axiosDelete(`upload/files/${fileId}`).catch((error) => {
      console.log(error);
    });
  };

  // Pre nomination steps

  const expireNomination = async () => {
    console.log('Expire Nominations');
    const nominations = await getNominationDataToExpire();
    if (nominations && nominations.length > 0) {
      await Promise.all(
        nominations.map(async (nomination) => {
          const updatedNomination = await axiosPut(
            `expire-nominations/${nomination.id}`,
            {
              data: { nomination },
            },
          );
          console.log(updatedNomination.data);
        }),
      );
    }
  };

  const archiveReferee = async () => {
    console.log('Archive Referees');
    const refereesToArchive = await getRefereesToArchive();
    console.log(refereesToArchive);
    if (refereesToArchive && refereesToArchive.length > 0) {
      await Promise.all(
        refereesToArchive.map(async (refereeToArchive) => {
          const updatedRefereeReport = await axiosPut(
            `archive-referees/${refereeToArchive.id}`,
            {
              data: { refereeToArchive },
            },
          );
          console.log(updatedRefereeReport.data);
        }),
      );
    }
  };

  const removeDraftNomination = async () => {
    console.log('Remove Draft Nomination');

    // find unpublished draft nominations
    const draftNominations = await axiosGet(
      `nomination-drafts?publicationState=preview`,
    );

    const newDraftNominations = draftNominations.data.data.filter(
      (draftNomination) =>
        !Object.values(candidates).includes(
          `${draftNomination.attributes.name} ${
            draftNomination.attributes.lastName
          }`,
        ),
    );

    await Promise.all(
      newDraftNominations.map(async (newDraftNomination) => {
        const { attributes } = newDraftNomination;
        const files = [
          attributes.disclosureDeclarationFileId,
          attributes.statutoryDeclarationFileId,
          attributes.curriculumVitaeFileId,
          attributes.extendedCitationFileId,
          attributes.nominationConsentFileId,
          attributes.publicationsFileId,
          attributes.proposerSignatureFileId,
          attributes.seconderSignatureFileId,
          attributes.supporterFourSignatureFileId,
          attributes.supporterOneSignatureFileId,
          attributes.supporterThreeSignatureFileId,
          attributes.supporterTwoSignatureFileId,
        ];
        console.log(files);
        await Promise.all(
          files.map(async (file) => {
            if (file?.id) await deleteFile(file.id);
          }),
        );
      }),
    );
    draftNominations.data.data.map(async (draftNomination) => {
      const deletedDraftNomination = await axiosPut(
        `remove-draft-nominations/${draftNomination.id}`,
        {
          data: { draftNomination },
        },
      );
      console.log(deletedDraftNomination);
    });
  };

  // Make a copy of referee report before changing status to draft
  const archiveRefereeReport = async () => {
    console.log('Archive Referee Report');

    try {
      // Get relevant nominations
      const nominations = await getRelevantNominations();

      for (const nomination of nominations) {
        const refereeReports = await getRefereeReports();
        const filteredReports = refereeReports.filter(
          (report) =>
            report.attributes.nominationId === nomination.id &&
            report.attributes.refereeArchived !== true,
        );

        await Promise.all(
          filteredReports.map(async (report) => {
            const {
              nominationId,
              refereeUserId,
              report: reportData,
            } = report.attributes;
            const latestState = await getLatestNominationState(nominationId);
            const { roundYear } = latestState.attributes;

            const archivedRefereeReport = await axiosGet(
              `referee-report-archives?filters[$and][0][nominationId][$eq]=${nominationId}&filters[$and][1][roundYear][$eq]=${roundYear}&filters[$and][2][refereeUserId][$eq]=${refereeUserId}&pagination[limit]=6000`,
            );

            const data = {
              ...report.attributes,
              roundYear,
              report: reportData.data ? reportData.data.id : null,
            };
            if (archivedRefereeReport?.data.data.length) {
              data.id = archivedRefereeReport.data.data[0].id;
              const updatedRefereeReportArchived = await axiosPut(
                `update-referee-report-archive/${archivedRefereeReport.data.data[0].id}`,
                {
                  data,
                },
              );
              console.log(updatedRefereeReportArchived.data);
            } else {
              const createdRefereeReportArchived = await axiosPost(
                `create-referee-report-archive`,
                {
                  data,
                },
              );
              console.log(createdRefereeReportArchived.data);
            }
          }),
        );
      }
    } catch (error) {
      console.error('Error occurred:', error);
    }
  };

  const updateNominationRefereeHistory = async () => {
    console.log('Update Nomination Referee History');
    const nominations = await getRelevantNominations();
    if (nominations && nominations.length > 0) {
      for (const nomination of nominations) {
        const latestNominationState = getLatestNominationState(nomination.id);
        const nominationReferee = await axiosGet(
          `nomination-referees?filters[nominationId][$eq]=${nomination.id}`,
        );
        if (nominationReferee.data.data[0]) {
          const refereeHistoryData = nominationReferee.data.data[0].attributes;
          if (latestNominationState) {
            // Find nomination referee history for the current nomination
            const nominationRefereeHistory = nominationRefereeHistoryArray.find(
              (history) =>
                history.attributes.nominationId === nomination.id &&
                history.attributes.roundYear ===
                  latestNominationState.attributes.roundYear,
            );

            if (refereeHistoryData) {
              refereeHistoryData.roundYear =
                latestNominationState.attributes.roundYear;
              console.log(refereeHistoryData);
              if (nominationRefereeHistory) {
                refereeHistoryData.id = nominationRefereeHistory.id;
                const updatedRefereeReportArchived = await axiosPut(
                  `update-nomination-referee-histories/${nominationRefereeHistory.id}`,
                  {
                    data: refereeHistoryData,
                  },
                );
                console.log(updatedRefereeReportArchived.data);
              } else {
                const createdRefereeReportArchived = await axiosPost(
                  'create-nomination-referee-histories',
                  {
                    data: refereeHistoryData,
                  },
                );
                console.log(createdRefereeReportArchived.data);
              }
            }
          }
        }
      }
    }
  };

  const updateSectionalCommitteeHistory = async () => {
    console.log('Update Sectional Committee History');

    const nominations = await getRelevantNominations();
    if (nominations && nominations.length > 0) {
      for (const nomination of nominations) {
        const latestNominationState = getLatestNominationState(nomination.id);

        if (latestNominationState) {
          const { nominationId, roundYear } = latestNominationState.attributes;
          const scHistory = sectionalCommitteeHistoryArray.find(
            (history) => history.attributes.nominationId === nominationId,
          );

          const data = {
            nominationId,
            [`sectionalCommitteeYear${roundYear}`]:
              sectionalCommittees[nomination.attributes.sectionalCommitteeId],
          };

          if (scHistory) {
            data.id = scHistory.id;

            const updatedRefereeReportArchived = await axiosPut(
              `update-sectional-committee-histories/${scHistory.id}`,
              {
                data,
              },
            );
            console.log(updatedRefereeReportArchived.data);
          } else {
            const createdRefereeReportArchived = await axiosPost(
              'create-sectional-committee-histories',
              {
                data,
              },
            );
            console.log(createdRefereeReportArchived.data);
          }
        }
      }
    }
  };

  const changeRefereeStatusToPending = async () => {
    console.log('Change Referee Status to Pending');
    const nominations = await getRelevantNominations();
    if (nominations && nominations.length > 0) {
      try {
        const refereeReportArray = await getRefereeReports();
        const filteredRefereeReport = refereeReportArray.filter(
          (refereeReport) =>
            nominations.some(
              (nom) => nom.id === refereeReport.attributes.nominationId,
            ),
        );
        const notPendingRefereeReport = filteredRefereeReport.filter(
          (refereeReport) => refereeReport.attributes.refereeStatusId !== 1,
        );
        notPendingRefereeReport.map(async (refereeReport) => {
          const updatedRefereeReport = await axiosPut(
            `change-referee-report-to-pending/${refereeReport.id}`,
            {
              data: {
                id: refereeReport.id,
                refereeStatusId: 1,
                requestEmailDate: null,
                reminderEmailDate: null,
                requestExtension: false,
                useExistingReport: false,
                decline: false,
                extensionReason: '',
                refereeDeclineSubmittedYear: null,
                declineReasonId: null,
                declineReasonOther: null,
                extensionReasonApprovalNote: null,
              },
            },
          );
          console.log(updatedRefereeReport.data);
        });
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
  };

  const updateNominationStateLastConsideredDate = async () => {
    console.log('Update Nomination State Round Year Last Considered Date');
    const nominations = await getRelevantNominations();
    if (nominations && nominations.length > 0) {
      try {
        const filteredNominationState = nominationStateArray.filter(
          (nomState) =>
            nominations.some(
              (nom) => nom.id === nomState.attributes.nominationId,
            ),
        );

        filteredNominationState.map(async (nomState) => {
          const updatedNominationState = await axiosPut(
            `update-nomination-state-last-considered-year/${nomState.id}`,
            {
              data: {
                id: nomState.id,
              },
            },
          );
          console.log(updatedNominationState.data);
        });
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
  };

  const updateNominationStateRoundYear = async () => {
    console.log('Update Nomination State Round Year');
    const nominations = await getRelevantNominations();
    if (nominations && nominations.length > 0) {
      try {
        const filteredNominationState = nominationStateArray.filter(
          (nomState) =>
            nominations.some(
              (nom) => nom.id === nomState.attributes.nominationId,
            ),
        );
        filteredNominationState.map(async (nomState) => {
          console.log(nomState);
          await axiosPut(
            `increment-nomination-state-round-year/${nomState.id}`,
            {
              data: {
                id: nomState.id,
                roundYear: nomState.attributes.roundYear + 1,
              },
            },
          );
        });
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
  };

  const incrementNumberOfYearsPreviouslyConsidered = async () => {
    console.log('Update number of years previously considered');
    const nominations = await getRelevantNominations();
    if (nominations && nominations.length > 0) {
      try {
        const filteredCandidatesState = candidateStateArray.filter(
          (candidateState) =>
            nominations.some(
              (nom) =>
                nom.attributes.candidateId ===
                candidateState.attributes.candidateId,
            ),
        );

        filteredCandidatesState.map(async (candidateState) => {
          const updatedCandidateState = await axiosPut(
            `increment-number-of-years-previously-considered/${candidateState.id}`,
            {
              data: {
                id: candidateState.id,
                consideredYears: candidateState.attributes.consideredYears + 1,
              },
            },
          );
          console.log(updatedCandidateState.data);
        });
      } catch (error) {
        console.error('Error occurred:', error);
      }
    }
  };

  const setNominationToDraft = async () => {
    console.log('Set Nomination to Draft');
    const nominations = await getRelevantNominationAndDrafts();
    console.log(nominations);
    if (nominations && nominations.length > 0) {
      nominations.map(async (nomination) => {
        const nominationDeclaration = await axiosGet(
          `nomination-declarations?filters[nominationId][$eq]=${nomination.id}&populate=*`,
        );
        if (nominationDeclaration.data.data) {
          const {
            nominationConsent,
            statutoryDeclaration,
            disclosureDeclaration,
          } = nominationDeclaration.data.data[0].attributes;
          if (nominationConsent.data)
            await deleteFile(nominationConsent.data.id);
          if (statutoryDeclaration.data)
            await deleteFile(statutoryDeclaration.data.id);
          if (disclosureDeclaration.data)
            await deleteFile(disclosureDeclaration.data.id);
        }
        const proposerSeconder = await axiosGet(
          `proposer-seconders?filters[nominationId][$eq]=${nomination.id}&populate=*`,
        );
        if (proposerSeconder.data.data) {
          const { proposerSignature } =
            proposerSeconder.data.data[0].attributes;
          if (proposerSignature.data)
            await deleteFile(proposerSignature.data.id);
        }

        await axiosPut(`set-nomination-to-draft/${nomination.id}`, {
          data: {
            id: nomination.id,
            nominationStatusTypeId: 1,
          },
        }).then((response) => {
          if (response.status === 200) {
            console.log(response.data.data);
          }
        });
      });
    }
  };

  const stepsFunctions = [
    expireNomination,
    archiveReferee,
    removeDraftNomination,
    archiveRefereeReport,
    updateNominationRefereeHistory,
    updateSectionalCommitteeHistory,
    changeRefereeStatusToPending,
    updateNominationStateLastConsideredDate,
    incrementNumberOfYearsPreviouslyConsidered,
    updateNominationStateRoundYear,
    setNominationToDraft,
  ];

  const [clickedSteps, setClickedSteps] = useState({});

  const steps = [
    "Any nomination that is year 5 and not 'elected' will be marked as expired",
    'Referees for all expired and elected nominations will be archived',
    'Remove Draft Nominations (nominationDraft)',
    'Copy Referees to Referee Report Archived',
    'Update Nomination Referee History',
    'Update Sectional Committee History',
    'Referees for relevant nominations will be changed to pending and requestEmailDate and reminderEmailDate is set to null',
    'Update last considered year in nomination state',
    'Update number of years previously considered',
    'Increment nomination year',
    'All relevant (not expired and not elected) nominations will be set back to draft',
  ];

  const runPreNominationSteps = async (stepIndex) => {
    // Execute the specific step function
    if (stepsFunctions[stepIndex]) {
      await stepsFunctions[stepIndex]();
    }

    // Update the state to mark this step as done
    setClickedSteps((prevState) => ({
      ...prevState,
      [stepIndex]: true,
    }));
  };
  return (
    <Card className="card-two mb-xl-5 table-responsive">
      <Card.Header>
        <h4 className="card-title">Pre-Nomination Steps</h4>
      </Card.Header>
      <Card.Body>
        The following actions will take place :
        <ol>
          {steps.map((step, index) => (
            <li key={index} className="step-item">
              <span className="step-text">{step}</span>
              <Button
                className="ml-2 step-button"
                onClick={() => runPreNominationSteps(index, setClickedSteps)}
                style={{
                  backgroundColor: clickedSteps[index] ? 'green' : '',
                  borderColor: clickedSteps[index] ? 'green' : '',
                }}
              >
                {clickedSteps[index] ? 'Done' : 'Run Step'}
              </Button>
            </li>
          ))}
        </ol>
      </Card.Body>
    </Card>
  );
};

export default PreNominationSteps;
