import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeAutolinkHeadings from 'rehype-autolink-headings';
import rehypeRaw from 'rehype-raw';
import rehypeSanitize from 'rehype-sanitize';
import PropTypes from 'prop-types';
import DOMpurify from 'dompurify';

const CustomAnchor = ({ href, children, ...props }) => (
  <button
    type="button"
    className="btn btn-outline-primary"
    onClick={() => window.open(href, '_blank')}
    {...props}
  >
    <i className="bi bi-link" /> {children}
  </button>
);

const Markdown = ({ text, className }) => {
  if (text) {
    return (
      <div className={className}>
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSanitize, rehypeAutolinkHeadings]}
        components={{ a: CustomAnchor }}
      >
        {DOMpurify.sanitize(text)}
      </ReactMarkdown>
      </div>
    );
  }

  return null;
};

CustomAnchor.propTypes = {
  children: PropTypes.node,
  href: PropTypes.string,
};

Markdown.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default Markdown;
