import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import PageTitle from '../layouts/PageTitle';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';
import { getUserRoles } from '../../utils/getUserRoles';
import Span from './Forms/Elements/Span';
import { useStaticCollections } from '../../context/StaticCollections';
import { axiosGet } from '../../utils/axiosGet';
import { axiosPut } from '../../utils/axiosPut';
import ModalConfirmation from './ModalConfirmation';
import NominationCSVExport from './NominationCSVExport';

const AdminActions = () => {
  const { userDetails, setUserDetails } = useLoggedInUserContextProvider();
  const { usersArray } = useStaticCollections();
  const [selectedUser, setSelectedUser] = useState(null);
  const [notification, setNotification] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [disableSubmissionEdit, setDisableSubmissionEdit] = useState(false);
  const [disableRefereeReportUploads, setDisableRefereeReportUploads] =
    useState(false);
  const [disableNewSubmission, setDisableNewSubmission] = useState(false);
  const [disableCommitteeAccess, setDisableCommitteeAccess] = useState(false);
  const [showModal, setShowModal] = useState(false); // Modal state

  const selectOptions = usersArray.map((user) => ({
    value: user.id,
    label: user.username,
  }));

  const handleChange = (selectedOption) => {
    setSelectedUser(selectedOption);
  };

  useEffect(() => {
    axiosGet(`admin-action`).then((response) => {
      const attributes = response?.data?.data?.attributes;

      if (attributes) {
        setDisableSubmissionEdit(attributes.disableSubmissionEdit);
        setDisableNewSubmission(attributes.disableNewSubmission);
        setDisableRefereeReportUploads(attributes.disableRefereeReportUploads);
        setDisableCommitteeAccess(attributes.disableCommitteeAccess);
      }
    });
  }, []);

  const masqueradeAsUser = async (selectedUserId) => {
    if (!selectedUserId) {
      return;
    }

    const masqueradeUser = usersArray.find(
      (user) => user.id === selectedUserId,
    );

    if (typeof masqueradeUser === 'object') {
      const userRoles = await getUserRoles(masqueradeUser);
      const hasAdminOrSuperAdmin = userRoles.some(
        (role) => role === 'admin' || role === 'super-admin',
      );

      masqueradeUser.email = userDetails.email;

      if (!hasAdminOrSuperAdmin) {
        setUserDetails({
          ...masqueradeUser,
          roles: userRoles,
        });

        setNotification('Updated');
      } else {
        setErrorMessage('Masquerading as an admin is not allowed');
      }
    }
  };

  const saveAdminAction = async () => {
    try {
      await axiosPut(`admin-action`, {
        data: {
          disableSubmissionEdit,
          disableNewSubmission,
          disableRefereeReportUploads,
          disableCommitteeAccess,
        },
      }).then((response) => {
        if (response.status === 200) {
          setNotification('Admin action saved successfully.');
        }
      });
    } catch (error) {
      console.error('Error saving admin action buttons', error);
    }
  };

  const handleSaveClick = () => {
    setShowModal(true); // Show modal on save click
  };

  const handleConfirmSelect = () => {
    saveAdminAction();
    setShowModal(false);
  };

  const handleCancelSelect = () => {
    setShowModal(false);
  };

  return (
    <>
      <PageTitle
        activeMenu="Admin Actions"
        motherMenu="Home"
        notificationMessage={notification}
      />
      <div>
        <div className="row mb-3">
          <div className="col-md-6 mb-3">
            <div className="card h-100 d-flex flex-column admin-card">
              <div className="card-header">
                <h4 className="card-title">Masquerade</h4>
              </div>
              <div className="card-body flex-grow-1">
                <label htmlFor="user-select">Masquerade as user</label>
                <Select
                  id="user-select"
                  value={selectedUser}
                  onChange={handleChange}
                  options={selectOptions}
                />
                <div className="mt-xl-2">
                  <Span name="user-select" text={errorMessage} />
                </div>
              </div>
              <div className="mt-auto text-end p-3">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={() => masqueradeAsUser(selectedUser.value)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-6 mb-3">
            <div className="card h-100 d-flex flex-column admin-card">
              <div className="card-header">
                <h4 className="card-title">
                  Enable/Disable nomination functionality
                </h4>
              </div>
              <div className="card-body flex-grow-1">
                <div className="form-check form-switch mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggleNewSubmission"
                    checked={disableNewSubmission}
                    onChange={() =>
                      setDisableNewSubmission(!disableNewSubmission)
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="toggleNewSubmission"
                  >
                    Disable new submission
                  </label>
                </div>
                <div className="form-check form-switch mt-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggleSubmissionEdits"
                    checked={disableSubmissionEdit}
                    onChange={() =>
                      setDisableSubmissionEdit(!disableSubmissionEdit)
                    }
                  />
                  <label
                    className="form-check-label"
                    htmlFor="toggleSubmissionEdits"
                  >
                    Disable submission edits
                  </label>
                </div>
                <div className="form-check form-switch mt-3">
                  <label className="form-check-label" htmlFor="toggleSubmit">
                    Disable referee report uploads
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggleSubmit"
                    checked={disableRefereeReportUploads}
                    onChange={() =>
                      setDisableRefereeReportUploads(
                        !disableRefereeReportUploads,
                      )
                    }
                  />
                </div>
                <div className="form-check form-switch mt-3">
                  <label className="form-check-label" htmlFor="toggleSubmit">
                    Disable Committee Access
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="toggleSubmit"
                    checked={disableCommitteeAccess}
                    onChange={() =>
                      setDisableCommitteeAccess(!disableCommitteeAccess)
                    }
                  />
                </div>
              </div>
              <div className="mt-auto text-end p-3">
                <button
                  type="button"
                  className="btn btn-success"
                  onClick={handleSaveClick}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <NominationCSVExport />
      </div>

      <ModalConfirmation
        showModal={showModal}
        handleCancelSelect={handleCancelSelect}
        bodyText="Are you sure you want to save?"
        handleConfirmSelect={handleConfirmSelect}
        setShowModal={setShowModal}
      />
    </>
  );
};

export default AdminActions;
