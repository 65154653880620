import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { axiosGet } from '../utils/axiosGet';

export const NominationOrdinaryElectedContext = createContext();

export const NominationOrdinaryElectedProvider = ({ children }) => {
  const [nominationOrdinaryElected, setNominationOrdinaryElected] = useState({
    nominationsElected: 0,
    nominationsElectedFemale: 0,
    nominationsElectedMan: 0,
    nominationsElectedPrefernottoanswer: 0,
    nominationsElectedWoman: 0,
    nominationsElectedByCommittee: {},
    nominationsElectedByCommitteeGender: {},
  });

  useEffect(() => {
    const fetchNominationOrdinary = async () => {
      try {
        const response = await axiosGet('nomination-ordinary-elected');

        if (response?.data) {
          const { data } = response;
          const nominationsElectedByCommittee = {};
          const nominationsElectedByCommitteeGender = {};

          Object.keys(data).forEach((key) => {
            if (key.startsWith('nominationsElectedByCommittee')) {
              const match = key.match(/(\d+)([A-Za-z]*)$/);

              if (match) {
                const [_, committeeId, gender] = match;

                if (!gender) {
                  nominationsElectedByCommittee[committeeId] = data[key];
                } else {
                  if (!nominationsElectedByCommitteeGender[committeeId]) {
                    nominationsElectedByCommitteeGender[committeeId] = {};
                  }
                  nominationsElectedByCommitteeGender[committeeId][gender] =
                    data[key];
                }
              }
            }
          });

          setNominationOrdinaryElected((prev) => ({
            ...prev,
            ...data,
            nominationsElectedByCommittee,
            nominationsElectedByCommitteeGender,
          }));
        }
      } catch (error) {
        console.error('Failed to fetch nomination ordinary data:', error);
      }
    };

    fetchNominationOrdinary();
  }, []);

  return (
    <NominationOrdinaryElectedContext.Provider
      value={nominationOrdinaryElected}
    >
      {children}
    </NominationOrdinaryElectedContext.Provider>
  );
};

NominationOrdinaryElectedProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
