import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { calculateColumnWidths } from '../../../../utils/calculateColumnWidths';
import { calculateDynamicStyle } from '../../../../utils/calculateDynamicStyle';
import { formatDate } from '../../../../utils/formatDate';
import { renderUpdatedSpan } from '../../../../utils/renderUpdatedSpan';
import { renderCustomIcon } from '../../../../utils/renderCustomIcon';
import { isDateToday } from '../../../../utils/isDateToday';

const TableRow = ({
  row,
  flexRender,
  formatSectionalCommitteeName = null,
  isSectionalCommitteeFormatted = null,
}) => {
  const cellRefs = useRef([]);
  const [columnWidths, setColumnWidths] = useState({});

  useEffect(() => {
    setColumnWidths(calculateColumnWidths(cellRefs.current, 3));
  }, [row]);

  const getCellValueByColumnId = (columnId) => {
    try {
      return row.getValue(columnId);
    } catch (error) {
      return row.original?.[columnId];
    }
  };

  const getAllValues = () => {
    const allValues = { ...row.original };

    row.getVisibleCells().forEach((cell) => {
      try {
        allValues[cell.column.id] = cell.getValue();
      } catch (error) {
        console.log(error);
      }
    });

    return allValues;
  };

  const values = getAllValues();

  return (
    <tr>
      {row.getVisibleCells().map((cell, cellIndex) => {
        let cellClassName;
        switch (cell.column.id) {
          case 'actions':
            cellClassName = 'td-center';
            break;
          case 'createdAt':
          case 'id':
          case 'nominationId':
          case 'nominationStatusTypeId':
          case 'publishedAt':
          case 'updatedAt':
            cellClassName = 'd-none';
            break;
          default:
            cellClassName = '';
            break;
        }

        let cellValue;
        try {
          if (cell.column.columnDef.cell) {
            cellValue = flexRender(
              cell.column.columnDef.cell,
              cell.getContext(),
            );
          } else {
            throw new Error('No cell renderer');
          }
        } catch (error) {
          try {
            cellValue = cell.getValue();
          } catch (valueError) {
            const columnId = cell.column.id;
            cellValue = row.original?.[columnId];
          }
        }

        if (cellValue === undefined || cellValue === null) {
          cellValue = row.original?.[cell.column.id] || '';
        }

        const sectionalCommittee = row.original?.sectionalCommittee;
        const sectionalCommitteeId = row.original?.sectionalCommitteeId;

        if (
          formatSectionalCommitteeName &&
          ['sectionalCommittee', 'sectionalCommitteeId'].includes(
            cell.column.id,
          ) &&
          isSectionalCommitteeFormatted
        ) {
          const cellOriginalValue =
            cell.getValue?.() || row.original?.[cell.column.id];
          const valueToFormat =
            cellOriginalValue || sectionalCommittee || sectionalCommitteeId;

          if (valueToFormat) {
            cellValue = formatSectionalCommitteeName(
              valueToFormat,
              isSectionalCommitteeFormatted,
            );
          }
        }

        return (
          <td
            key={cell.id}
            className={cellClassName}
            ref={(el) => (cellRefs.current[cellIndex] = el)}
            style={calculateDynamicStyle(columnWidths[cellIndex])}
          >
            {Object.values(values).length - 1 === cellIndex &&
            getCellValueByColumnId('publishedAt') !== undefined
              ? formatDate(getCellValueByColumnId('publishedAt'))
              : cellValue}{' '}
            {cellIndex === 1 &&
              renderUpdatedSpan(getCellValueByColumnId('publishedAt'))}
            {cell.column.id === 'requestEmailDate' &&
              isDateToday(getCellValueByColumnId('requestEmailDate')) &&
              renderCustomIcon(getCellValueByColumnId('updatedAt'))}
            {cell.column.id === 'reminderEmailDate' &&
              isDateToday(getCellValueByColumnId('reminderEmailDate')) &&
              renderCustomIcon(getCellValueByColumnId('updatedAt'))}
            {cellIndex === 1 && row.original?.refereeArchived && (
              <span className="archived-span">Archived</span>
            )}
          </td>
        );
      })}
    </tr>
  );
};

TableRow.propTypes = {
  row: PropTypes.object.isRequired,
  flexRender: PropTypes.func.isRequired,
  formatSectionalCommitteeName: PropTypes.func,
  isSectionalCommitteeFormatted: PropTypes.bool,
};

export default TableRow;
