export const validateFellowNames = (
  firstName,
  lastName,
  email,
  fellows,
  fieldPrefix,
  errors,
) => {
  if (!firstName || !lastName) return;

  const matchingFellows = fellows.filter(
    (fellow) =>
      fellow.attributes.name.toLowerCase() === firstName.toLowerCase() &&
      fellow.attributes.lastName.toLowerCase() === lastName.toLowerCase(),
  );

  if (errors[`${fieldPrefix}Name`]?.type === 'manual') {
    delete errors[`${fieldPrefix}Name`];
  }

  if (errors[`${fieldPrefix}LastName`]?.type === 'manual') {
    delete errors[`${fieldPrefix}LastName`];
  }

  if (matchingFellows.length > 0) {
    let errorMessage;

    if (matchingFellows.length === 1) {
      const matchingFellow = matchingFellows[0];
      errorMessage = `We found a fellow with a similar name: ${matchingFellow.attributes.name} ${
        matchingFellow.attributes.lastName
      } (${matchingFellow.attributes.email}). If this is the same person, please select them from the dropdown above.`;
    } else {
      errorMessage = `We found ${matchingFellows.length} fellows with this name. If you're referring to an existing fellow, please select them from the dropdown above.`;
    }

    errors[`${fieldPrefix}Name`] = {
      type: 'info',
      message: errorMessage,
      ref: { name: `${fieldPrefix}Name` },
    };

    errors[`${fieldPrefix}LastName`] = {
      type: 'info',
      message: errorMessage,
      ref: { name: `${fieldPrefix}LastName` },
    };

    return matchingFellows;
  }

  return null;
};
