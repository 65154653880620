export const normalizeText = (text) => {
  if (text === null || text === undefined) {
    return '';
  }

  const stringText = String(text);

  return stringText
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();
};
