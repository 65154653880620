import { normalizeText } from './textNormalization';

export const accentInsensitiveTextFilter = (row, columnId, filterValue) => {
  const normalizedFilterValue = normalizeText(filterValue);
  const value = row.getValue(columnId);

  if (value === null || value === undefined) return false;

  const normalizedValue = normalizeText(String(value));
  return normalizedValue.includes(normalizedFilterValue);
};

export const accentInsensitiveGlobalFilter = (row, columnId, filterValue) => {
  if (!filterValue) return true;

  const normalizedFilterValue = normalizeText(filterValue);
  const value = row.getValue(columnId);

  if (value === null || value === undefined) return false;

  const normalizedValue = normalizeText(String(value));

  return normalizedValue.includes(normalizedFilterValue);
};
