import React from 'react';
import PropTypes from 'prop-types';

const TableHeader = ({ headerGroups, filter = false, flexRender }) => (
  <thead className="thead-dark">
    {headerGroups.map((headerGroup) => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map((column) => {
          let tableHeaderClassName = '';
          switch (column.id) {
            case 'createdAt':
            case 'id':
            case 'nominationId':
            case 'nominationStatusTypeId':
            case 'publishedAt':
            case 'updatedAt':
              tableHeaderClassName = 'd-none';
              break;
            default:
              tableHeaderClassName = '';
              break;
          }

          const canSort = column.column.getCanSort();
          const sortingFn = canSort
            ? () => column.column.toggleSorting()
            : undefined;
          const sortingState = column.column.getIsSorted();

          let sortIndicator = '';
          if (sortingState) {
            sortIndicator = sortingState === 'desc' ? ' 🔽' : ' 🔼';
          }

          const canFilter = column.column.getCanFilter();

          return (
            <th
              key={column.id}
              onClick={sortingFn}
              className={tableHeaderClassName}
              style={canSort ? { cursor: 'pointer' } : undefined}
            >
              {flexRender(column.column.columnDef.header, column.getContext())}
              {sortIndicator}
              {filter && canFilter && (
                <div
                  tabIndex="0"
                  role="button"
                  onClick={(e) => e.stopPropagation()}
                  onKeyDown={(e) => e.stopPropagation()}
                >
                  {column.column.columnDef.Filter ? (
                    column.column.columnDef.Filter({
                      column: column.column,
                      table: headerGroup.table,
                    })
                  ) : (
                    <DefaultColumnFilter column={column.column} />
                  )}
                </div>
              )}
            </th>
          );
        })}
      </tr>
    ))}
  </thead>
);

const DefaultColumnFilter = ({ column }) => {
  const filterValue = column.getFilterValue();

  return (
    <input
      className="form-control form-control-sm mt-1"
      value={filterValue || ''}
      onChange={(e) => {
        column.setFilterValue(e.target.value || undefined);
      }}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

TableHeader.propTypes = {
  headerGroups: PropTypes.array.isRequired,
  filter: PropTypes.bool,
  flexRender: PropTypes.func.isRequired,
};

DefaultColumnFilter.propTypes = {
  column: PropTypes.object.isRequired,
};

export default TableHeader;
