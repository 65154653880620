import React, { useContext, useEffect, useState, useMemo } from 'react';

import FilteringTable from './Table/FilteringTable/FilteringTable';
import ColumnFilter from './Table/FilteringTable/ColumnFilter';
import PageTitle from '../layouts/PageTitle';

import { tableColumnNamesNominationAdministration } from '../../utils/tableColumnNamesNominationAdministration';
import { tableRowsValuesNominationAdministration } from '../../utils/tableRowsValuesNominationAdministration';
import { arrayToFlatObject } from '../../utils/arrayToFlatObject';
import AdminNominationCell from './AdminNominationCell';

import { CommonCollections } from '../../App';

import { filteringTableGetTableColumns } from '../../utils/filteringTableGetTableColumns';
import { filteringTableFilterUniqueCandidates } from '../../utils/filteringTableFilterUniqueCandidates';
import { useStaticCollections } from '../../context/StaticCollections';
import { useAdminCollections } from '../../context/AdminCollections';

const AdminNomination = () => {
  const {
    electionTypes,
    fellows,
    nominationStatusTypes,
    sectionalCommitteesWithCmse,
    usersArray,
  } = useStaticCollections();

  const {
    candidates,
    nominationArray,
    nominationStateArray,
    proposerSeconder,
    draftNominationArray,
  } = useAdminCollections();

  const updatedArray = useMemo(
    () =>
      draftNominationArray.map((item) => ({
        ...item,
        id: `${item.id}d`,
      })),
    [draftNominationArray],
  );

  const mergedData = useMemo(
    () => [...nominationArray, ...updatedArray],
    [nominationArray, updatedArray],
  );

  const { setNominationId } = useContext(CommonCollections);

  const [nominationsData, setNominationsData] = useState([]);
  const [nominationsColumns, setNominationsColumns] = useState([]);

  useEffect(() => {
    if (sessionStorage.getItem('formNomination') !== undefined) {
      sessionStorage.removeItem('formNomination');
    }

    if (sessionStorage.getItem('draftId') !== undefined) {
      sessionStorage.removeItem('draftId');
    }

    const tableColumns = filteringTableGetTableColumns(
      tableColumnNamesNominationAdministration,
      ColumnFilter,
      AdminNominationCell,
    );

    const proposers = proposerSeconder.map((item) => ({
      [item.attributes.nominationId]: item.attributes.proposerFellowId,
    }));

    const proposerSeconderObj = arrayToFlatObject(proposers);

    const tableData = tableRowsValuesNominationAdministration(
      mergedData,
      proposerSeconderObj,
      electionTypes,
      nominationStatusTypes,
      sectionalCommitteesWithCmse,
      fellows,
      nominationStateArray,
      usersArray,
    );

    setNominationsColumns(tableColumns);

    const tableDataTwo = Object.values(tableData).map((nomination) => {
      if (candidates[nomination.candidateId] !== undefined) {
        nomination.candidate = candidates[nomination.candidateId];
      }

      return nomination;
    });

    const filteredData = filteringTableFilterUniqueCandidates(tableDataTwo);

    setNominationsData(filteredData);
    setNominationId(null);
  }, [
    electionTypes,
    nominationStatusTypes,
    sectionalCommitteesWithCmse,
    fellows,
    candidates,
    proposerSeconder,
    nominationArray,
    nominationStateArray,
    setNominationId,
    usersArray,
    mergedData,
  ]);

  return (
    <>
      <PageTitle activeMenu="Nomination" motherMenu="Home" />
      <FilteringTable
        componentName="AdminNomination"
        columns={nominationsColumns}
        data={nominationsData}
        urlString="application-administration"
        buttonText="New Nomination"
      />
    </>
  );
};

export default AdminNomination;
