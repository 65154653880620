import React from 'react';

import ReactApexChart from 'react-apexcharts';
import PropTypes from 'prop-types';

const ApexBar2 = ({ title, xValues, xLabels, colors }) => {
  const data = {
    series: [
      {
        name: title,
        data: xValues,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        height: 230,
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
      colors,
      legend: {
        show: false,
        position: 'top',
        horizontalAlign: 'left',
      },
      dataLabels: {
        enabled: false,
        offsetX: -6,
        style: {
          fontSize: '12px',
          // colors: ["#fff"],
        },
      },
      stroke: {
        show: false,
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
      xaxis: {
        show: false,
        categories: xLabels,
      },
    },
  };

  const { options } = data;
  const { series } = data;

  return (
    <div id="chart" className="line-chart-style bar-chart">
      <ReactApexChart
        options={options}
        series={series}
        type="bar"
        height={300}
      />
    </div>
  );
};

ApexBar2.propTypes = {
  title: PropTypes.string,
  xValues: PropTypes.array,
  xLabels: PropTypes.array,
  colors: PropTypes.array,
};

export default ApexBar2;
