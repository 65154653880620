import React, { useEffect, useState, useContext } from 'react';

import FilteringTable from './Table/FilteringTable/FilteringTable';
import ColumnFilter from './Table/FilteringTable/ColumnFilter';
import PageTitle from '../layouts/PageTitle';

import { tableColumnNamesRefereeAdmin } from '../../utils/tableColumnNamesRefereeAdmin';
import { arrayToFlatObject } from '../../utils/arrayToFlatObject';

import { tableRowsValuesRefereeAdministration } from '../../utils/tableRowsValuesRefereeAdministration';
import { CommonCollections } from '../../App';
import RefereeReportExtensionRequestCell from './RefereeReportExtensionRequestCell';
import { filteringTableGetTableColumns } from '../../utils/filteringTableGetTableColumns';
import { useAdminCollections } from '../../context/AdminCollections';
import { useStaticCollections } from '../../context/StaticCollections';

const RefereeReportExtensionRequest = () => {
  const {
    fellows,
    declineReasons,
    nominationStatusTypes,
    refereeStatuses,
    sectionalCommitteesWithCmse,
    users,
  } = useStaticCollections();

  const {
    refereeReports,
    refreshDynamicCollections,
    setRefereeReportId,
    nonAasReferees,
  } = useContext(CommonCollections);

  const { candidates, nominationArray } = useAdminCollections();

  const [refereesData, setRefereesData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);

  useEffect(() => {
    const filteredRefereeReports = refereeReports.filter(
      (report) => report.attributes.refereeStatusId === 5,
    );
    const tableColumns = filteringTableGetTableColumns(
      tableColumnNamesRefereeAdmin,
      ColumnFilter,
      RefereeReportExtensionRequestCell,
    );

    const tableData = tableRowsValuesRefereeAdministration(
      filteredRefereeReports,
      refereeStatuses,
      declineReasons,
    );

    setTableColumn(tableColumns);
    const refereeData = [tableData.map((item) => item.nominationId), tableData];

    const nominationArr = nominationArray.map((item) => ({
      [item.id]: item.attributes,
    }));

    const nominationsObj = arrayToFlatObject(nominationArr);

    const tableDataTwo = Object.values(refereeData[1]).map((referee) => {
      if (nominationsObj[referee.nominationId] !== undefined) {
        referee.candidate =
          candidates[nominationsObj[referee.nominationId].candidateId];
        referee.sectionalCommittee =
          sectionalCommitteesWithCmse[
            nominationsObj[referee.nominationId].sectionalCommitteeId
          ];
        referee.nominationStatus =
          nominationStatusTypes[
            nominationsObj[referee.nominationId].nominationStatusTypeId
          ];
      }
      referee.refereeUser = referee.refereeFellowId
        ? fellows[referee.refereeFellowId]
        : nonAasReferees[referee.nonAasRefereeId];
      return referee;
    });
    tableDataTwo.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    setRefereesData(tableDataTwo);
    setRefereeReportId(null);
  }, [
    refereeStatuses,
    sectionalCommitteesWithCmse,
    users,
    declineReasons,
    fellows,
    candidates,
    nominationArray,
    refereeReports,
    refreshDynamicCollections,
    setRefereeReportId,
    nominationStatusTypes,
    nonAasReferees,
  ]);

  return (
    <>
      <PageTitle activeMenu="Referee Extension Request" motherMenu="Home" />
      <FilteringTable
        componentName="RefereeReportExtensionRequest"
        columns={tableColumn}
        data={refereesData}
      />
    </>
  );
};

export default RefereeReportExtensionRequest;
