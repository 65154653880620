import React from 'react';
import { Bar } from 'react-chartjs-2';
import PropTypes from 'prop-types';

const BarCategories = ({ genderDataSets }) => {
  const chartData = {
    defaultFontFamily: 'Poppins',
    labels: [
      'SC1: Mathematics',
      'SC2: Physics and astronomy',
      'SC3: Chemistry',
      'SC4: Earth and planetary sciences',
      'SC5: Engineering sciences',
      'SC6: Information and communication sciences',
      'SC7: Plant and animal sciences',
      'SC8: Ecology, environment and evolution',
      'SC9: Molecular and cell biology, and human genetics',
      'SC10: Immunology, microbiology and haematology',
      'SC11: Physiology and neuroscience ',
      'SC12: Medicine, dentistry and health sciences',
      'SC13: Interdisciplinary',
    ],
    datasets: genderDataSets.map(({ label, data: genderData, color }) => ({
      label,
      backgroundColor: color,
      hoverBackgroundColor: color,
      data: genderData,
    })),
  };

  const options = {
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: false,
      },
      tooltips: {
        mode: 'index',
        intersect: false,
      },
      responsive: true,
    },
    scales: {
      x: {
        stacked: true,
      },
      y: {
        stacked: true,
        suggestedMax:
          Math.max(
            ...genderDataSets.flatMap(({ data }) =>
              data.filter((d) => typeof d === 'number'),
            ),
            0,
          ) + 2,
      },
    },
  };

  return <Bar data={chartData} height={150} options={options} />;
};

BarCategories.propTypes = {
  genderDataSets: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      data: PropTypes.array.isRequired,
      color: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default BarCategories;
