import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from 'react-bootstrap/Dropdown';

const DropdownColumnVisibility = ({
  columns,
  columnVisibility,
  handleColumnToggle,
}) => {
  const filterColumns = (col) => {
    const { id } = col;

    return (
      (!id.includes('Id') ||
        id === 'sectionalCommitteeId' ||
        id === 'electionTypeId') &&
      !id.includes('At') &&
      id !== 'id' &&
      !id.includes('actions') &&
      !id.includes('candidate')
    );
  };

  const isChecked = (col) => {
    if (Object.keys(columnVisibility).length === 0) {
      return true;
    }

    if (!(col.id in columnVisibility)) {
      return true;
    }

    return columnVisibility[col.id];
  };

  return (
    <Dropdown>
      <Dropdown.Toggle
        variant="outline-primary"
        id="column-visibility-dropdown"
        className="w-100"
      >
        Toggle Columns
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {columns &&
          columns.filter(filterColumns).map((col) => (
            <Dropdown.Item
              as="label"
              key={col.id}
              className="d-flex align-items-center"
              onClick={(e) => e.stopPropagation()}
            >
              <input
                type="checkbox"
                checked={isChecked(col)}
                onChange={() => handleColumnToggle(col.id)}
                className="me-1 custom-checkbox"
              />
              <span className="ms-1">{col.header}</span>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

DropdownColumnVisibility.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
    }),
  ).isRequired,
  columnVisibility: PropTypes.objectOf(PropTypes.bool).isRequired,
  handleColumnToggle: PropTypes.func.isRequired,
};

export default DropdownColumnVisibility;
