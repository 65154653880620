import React from 'react';
import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';

const CsvExport = ({
  rows,
  columns,
  columnVisibility,
  filename = 'table_data.csv',
}) => {
  const excludedColumns = [
    'nominationId',
    'id',
    'nominationStatusTypeId',
    'actions',
    'createdAt',
    'updatedAt',
    'publishedAt',
  ];

  const visibleColumns = columns.filter(
    (col) =>
      columnVisibility[col.id] !== false && !excludedColumns.includes(col.id),
  );

  const csvHeaders = visibleColumns.map((col) => ({
    label: col.header,
    key: col.id,
  }));

  const csvData = rows.map((row) => {
    const csvRow = {};
    visibleColumns.forEach((col) => {
      const key = col.accessorKey || col.id;
      csvRow[col.id] = row?.original?.[key] || '';
    });
    return csvRow;
  });

  return (
    <CSVLink
      headers={csvHeaders}
      data={csvData}
      filename={filename}
      className="btn btn-outline-primary w-100"
    >
      <i className="bi bi-file-earmark-arrow-down me-2" /> Export CSV
    </CSVLink>
  );
};

CsvExport.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  columnVisibility: PropTypes.object.isRequired,
  filename: PropTypes.string,
};

export default CsvExport;
