import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';

import ChartPie from '../charts/Chartjs/pie';
import PageTitle from '../../layouts/PageTitle';
import CardSummary from '../CardSummary';
import ComponentCardHolder from '../ColumnCardHolder';
import BarCategories from '../BarCategories';

import { NominationOrdinaryContext } from '../../../context/NominationOrdinaryProvider';

const calculatePercentage = (total, number) =>
  total > 0 ? `${Math.round((number * 100) / total)}%` : '0%';

const getGenderNeutralColor = (index) => {
  const modernColors = [
    'rgba(0, 162, 255, 1)',
    'rgba(258, 128, 25,0.5)',
    'rgba(153, 218, 255, 0.7)',
    'rgba(0, 0, 0, 0.07)',
  ];
  return (
    modernColors[index] ||
    `rgba(${Math.floor(Math.random() * 156) + 100}, ${Math.floor(Math.random() * 156) + 100}, ${Math.floor(Math.random() * 156) + 100}, 1)`
  );
};

const ShortListedCandidates = () => {
  const {
    nominationsShortlisted = 0,
    nominationsShortlistedFemale = 0,
    nominationsShortlistedMan = 0,
    nominationsShortlistedPrefernottoanswer = 0,
    nominationsShortlistedWoman = 0,
    nominationsShortlistedByCommittee = {},
    nominationsShortlistedByCommitteeGender = {},
  } = useContext(NominationOrdinaryContext);

  const committeeIds = Object.keys(nominationsShortlistedByCommittee);
  const committeeLabels = committeeIds.map((id) => `Committee ${id}`);
  const committeeData = committeeIds.map(
    (id) => nominationsShortlistedByCommittee[id],
  );

  const allGenders = new Set();
  committeeIds.forEach((id) => {
    if (nominationsShortlistedByCommitteeGender[id]) {
      Object.keys(nominationsShortlistedByCommitteeGender[id]).forEach(
        (gender) => {
          allGenders.add(gender);
        },
      );
    }
  });

  const genderCategories = Array.from(allGenders);

  const genderDataSets = genderCategories.map((gender, index) => ({
    label: gender,
    data: committeeIds.map(
      (id) => nominationsShortlistedByCommitteeGender[id]?.[gender] || 0,
    ),
    color: getGenderNeutralColor(index),
  }));

  let genderChartComponent;
  if (genderDataSets.length === 0) {
    genderChartComponent = <p>No data available</p>;
  } else if (genderDataSets.length === 1) {
    genderChartComponent = (
      <BarCategories
        genderDataSets={[genderDataSets[0]]}
        labels={committeeLabels}
      />
    );
  } else {
    genderChartComponent = (
      <BarCategories genderDataSets={genderDataSets} labels={committeeLabels} />
    );
  }

  return (
    <div className="h-80">
      <PageTitle
        motherMenu="Short-listed Ordinary Nominations"
        activeMenu="Report 2025"
      />
      <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6" />
      <Row>
        <CardSummary
          title="Short-listed Candidates"
          progressBarNumber={nominationsShortlisted}
          description={`Total: ${nominationsShortlisted}`}
          numberOfRows={6}
        />
        <CardSummary
          title="Female Short-listed Candidates"
          spanText={calculatePercentage(
            nominationsShortlisted,
            nominationsShortlistedFemale,
          )}
          progressBarNumber={nominationsShortlistedFemale}
          totalNumber={nominationsShortlisted}
          description={`Total: ${nominationsShortlisted}`}
          numberOfRows={6}
        />
        <ComponentCardHolder
          title="Short-listed Candidates by Gender"
          component={
            <ChartPie
              pieData={[
                nominationsShortlistedMan,
                nominationsShortlistedWoman,
                nominationsShortlistedPrefernottoanswer,
              ]}
              labels={['Male', 'Female', 'Prefer not to answer']}
            />
          }
          numberOfRows={12}
        />
        <ComponentCardHolder
          title="Sectional Committee"
          component={
            <BarCategories
              genderDataSets={[
                {
                  label: 'Total',
                  data: committeeData,
                  color: 'rgba(79, 129, 189, 1)',
                },
              ]}
              labels={committeeLabels}
            />
          }
          numberOfRows={12}
        />
        <ComponentCardHolder
          title="Sectional Committee - Gender"
          component={genderChartComponent}
          numberOfRows={12}
        />
      </Row>
    </div>
  );
};

export default ShortListedCandidates;
