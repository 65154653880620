import { tableColumnNamesRefereeAdmin } from './tableColumnNamesRefereeAdmin';
import RefereeStatus from '../jsx/components/RefereeStatus';

export const filteringTableGetTableColumns = (
  tableColumnNames,
  ColumnFilter,
  Cell,
) =>
  Object.keys(tableColumnNames).map((item) => {
    const columnId =
      item === 'Referee Status'
        ? tableColumnNamesRefereeAdmin[item]
        : tableColumnNames[item];

    const column = {
      id: String(columnId),
      accessorKey: typeof columnId === 'string' ? columnId : undefined,
      accessorFn:
        typeof columnId !== 'string' ? (row) => row[columnId] : undefined,
      header: item,
      enableSorting: true,
      enableGlobalFilter: true,
      enableColumnFilter: true,
      filterFn: 'accentInsensitive',
    };

    if (item === 'Actions') {
      column.cell = (info) => {
        try {
          const value = info.getValue?.() ?? info.row.original[columnId];
          return Cell({ value, row: { original: info.row.original } });
        } catch {
          return info.row.original[columnId] || '';
        }
      };
    }

    if (item === 'Referee Status') {
      column.cell = (info) => {
        try {
          const value = info.getValue?.() ?? info.row.original[columnId];
          return RefereeStatus({ value, row: { original: info.row.original } });
        } catch {
          return info.row.original[columnId] || '';
        }
      };
    }

    return column;
  });
