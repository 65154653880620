import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { axiosGet } from '../utils/axiosGet';

export const NominationCmSeContext = createContext();

export const NominationCmSeProvider = ({ children }) => {
  const [nominationCmSeData, setNominationCmSeData] = useState({
    nominationsCmAndSe: 0,
    femaleSe: 0,
  });

  useEffect(() => {
    const fetchNominationCmSeData = async () => {
      try {
        const response = await axiosGet('nomination-cm-se');

        if (response?.data) {
          setNominationCmSeData(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch nomination CmSe reports:', error);
      }
    };

    fetchNominationCmSeData();
  }, []);

  return (
    <NominationCmSeContext.Provider value={nominationCmSeData}>
      {children}
    </NominationCmSeContext.Provider>
  );
};

NominationCmSeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
