import { jsPDF } from 'jspdf';
import { PDFDocument, rgb } from 'pdf-lib';

const camelCaseToTitle = (str) =>
  str
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/^\w/, (c) => c.toUpperCase());

const getTextWidth = (text, fontSize) => {
  const longestLine = text
    .split('\n')
    .reduce((a, b) => (a.length > b.length ? a : b), '');
  return longestLine.length * fontSize * 0.5;
};

const numberWords = ['One', 'Two', 'Three', 'Four', 'Five', 'Six'];

export const handleDownload = async (candidate, pdfUrls, refereeReportUrls) => {
  const elements = document.querySelectorAll('.card');

  if (elements.length === 0) {
    console.error('No elements with class "card" found.');
    return;
  }

  if (refereeReportUrls) {
    let independentAssessorCount = 0;
    refereeReportUrls.forEach((referee, index) => {
      const baseText = `\n\nSubmitted by ${referee.label}\nFor the ${referee.roundYear} election round`;
      if (referee.independentAssessor) {
        referee.name = `independentAssessor${numberWords[independentAssessorCount]}:${baseText}`;
        independentAssessorCount += 1;
      } else {
        referee.name = `referee${numberWords[index]}:${baseText}`;
      }
    });
  }

  const clonedElements = Array.from(elements).map((element) => {
    const clonedElement = element.cloneNode(true);

    clonedElement.classList.remove('card');
    const h4Element = clonedElement.querySelector('h4.card-title');
    if (h4Element?.textContent.trim() === 'Nomination View') {
      h4Element.remove();
    }

    clonedElement.querySelectorAll('*').forEach((child) => {
      child.removeAttribute('style');

      child.style.width = '100%';
      child.style.maxWidth = '100%';
    });

    clonedElement.removeAttribute('style');
    clonedElement.className = '';

    clonedElement.querySelectorAll('h3').forEach((h3) => {
      h3.style.fontSize = '24px';
      h3.style.fontWeight = 'bold';
    });

    clonedElement.querySelectorAll('span').forEach((span) => {
      span.style.fontWeight = 'bold';
    });

    clonedElement
      .querySelectorAll('button')
      .forEach((button) => button.remove());

    return clonedElement;
  });

  const mergedPdfsArray = [...pdfUrls, ...(refereeReportUrls || [])];
  const pdf = jsPDF('p', 'mm', 'a4');

  const tempElement = document.createElement('div');
  tempElement.innerHTML = clonedElements
    .map((element) => element.outerHTML)
    .join('\n');

  Object.assign(tempElement.style, {
    fontFamily: 'Arial, sans-serif',
    fontSize: '20px',
    lineHeight: '2',
    color: 'black',
    pageBreakInside: 'avoid',
    width: '920px',
    height: '50%',
    whiteSpace: 'normal',
    overflow: 'hidden',
    marginRight: '20px',
  });

  document.body.appendChild(tempElement);

  try {
    await pdf.html(tempElement, {
      callback: async () => {
        document.body.removeChild(tempElement);
        const newPdfDoc = await PDFDocument.create();

        const frontPage = newPdfDoc.addPage([595.28, 841.89]);
        const title = 'Nomination Details';
        frontPage.drawText(title, {
          x: (frontPage.getWidth() - getTextWidth(title, 16)) / 2,
          y: 400,
          size: 18,
          color: rgb(0, 0, 0),
        });

        const [generatedPdfDoc, ...additionalPdfDocs] = await Promise.all([
          PDFDocument.load(pdf.output('arraybuffer'), {
            ignoreEncryption: true,
          }),
          ...(await Promise.all(
            mergedPdfsArray.map(async (pdfUrl) => {
              const response = await fetch(pdfUrl.path);
              if (!response.ok) {
                throw new Error(`Failed to fetch PDF: ${response.statusText}`);
              }
              return PDFDocument.load(await response.arrayBuffer(), {
                ignoreEncryption: true,
              });
            }),
          )),
        ]);

        const generatedPages = await newPdfDoc.copyPages(
          generatedPdfDoc,
          generatedPdfDoc.getPageIndices(),
        );

        generatedPages.forEach((page) => {
          const { width, height } = page.getSize();
          page.setMediaBox(0, -50, width, height + 100);
          page.setCropBox(0, -50, width, height + 100);
          newPdfDoc.addPage(page);
        });

        for (let i = 0; i < additionalPdfDocs.length; i += 1) {
          const blankPage = newPdfDoc.addPage([595.28, 841.89]);
          const publicationName = camelCaseToTitle(mergedPdfsArray[i].name);

          blankPage.drawText(publicationName, {
            x: (blankPage.getWidth() - getTextWidth(publicationName, 16)) / 2,
            y: 400,
            size: 18,
            color: rgb(0, 0, 0),
          });

          const additionalPages = await newPdfDoc.copyPages(
            additionalPdfDocs[i],
            additionalPdfDocs[i].getPageIndices(),
          );
          additionalPages.forEach((page) => newPdfDoc.addPage(page));
        }

        const newPdfBlob = new Blob([await newPdfDoc.save()], {
          type: 'application/pdf',
        });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(newPdfBlob);
        link.download = `${candidate}.pdf`;
        link.click();
      },
      x: 20,
      y: 5,
      width: 180,
      height: tempElement.scrollHeight + 50,
      html2canvas: {
        scale: 0.2,
        dpi: 300,
        letterRendering: true,
        useCORS: true,
        allowTaint: true,
      },
      autoPaging: 'text',
      pagebreak: { mode: ['avoid-all', 'css', 'legacy'] },
    });
  } catch (error) {
    console.error('Error generating PDF:', error);
    const errorMessage = error.message.includes('fetch')
      ? 'Unable to fetch required documents. Please check your connection and try again.'
      : 'Download unsuccessful. Please reach out to our support team at ict@science.org.au for assistance in resolving the issue.';
    alert(errorMessage);
  }
};
