import { axiosGet } from './axiosGet';
import { axiosPost } from './axiosPost';
import { axiosPut } from './axiosPut';

function handleNominationUpdate(
  nominationId,
  draftFormData,
  userDetails,
  refreshNominations,
  setRefreshNominations,
) {
  if (nominationId && typeof nominationId === 'number') {
    axiosGet(`nominations/${nominationId}`).then((nominationsResponse) => {
      if (nominationsResponse) {
        axiosPut(`nominations/${nominationId}`, {
          data: {
            value: draftFormData,
          },
        }).then((nominationUpdate) => {
          if (nominationUpdate && nominationUpdate?.status === 200) {
            setTimeout(() => {
              if (userDetails?.roles?.includes('admin')) {
                setRefreshNominations(!refreshNominations);
              }
            }, 2000);
          }
        });
      }
    });
  }
}

export const saveNominationDraft = (
  refreshCollections,
  setRefreshCollections,
  userDetails,
  nominationId,
  refreshNominations,
  setRefreshNominations,
  usersArray,
  errors,
  numberOfErrors,
) => {
  const collectionName = 'nomination-drafts';
  const draftFormData = sessionStorage?.formNomination;
  const draftFormParsedData = JSON.parse(draftFormData);
  const proposerFellowId = draftFormParsedData?.proposerFellowId?.value;
  const proposerUser = usersArray.find(
    (user) => user?.fellowId === proposerFellowId,
  );

  const proposerUserId = proposerUser?.id ?? userDetails?.id;
  const { name, lastName } = draftFormParsedData;

  if (
    name &&
    lastName &&
    name.length !== 0 &&
    lastName.length !== 0 &&
    !(
      errors?.name?.type === 'expired-nomination' ||
      errors?.lastName?.type === 'expired-nomination' ||
      errors?.email?.type === 'expired-nomination'
    )
  ) {
    if (proposerUserId !== userDetails?.id) {
      axiosGet(
        `${collectionName}?filters[userId][$eq]=${userDetails?.id}&filters[name][$eq]=${name}&filters[lastName][$eq]=${lastName}`,
      ).then((nominationDraftResponse) => {
        if (nominationDraftResponse?.data?.data?.length !== 0) {
          const draftId = nominationDraftResponse.data.data[0].id;

          axiosPut(`nomination-drafts/${draftId}`, {
            data: {
              publishedAt: null,
            },
          }).then((updateResponse) => {
            if (updateResponse.status === 200) {
              console.log(updateResponse);
            }
          });
        }
      });
    }

    axiosGet(
      `${collectionName}?filters[userId][$eq]=${proposerUserId}&filters[name][$eq]=${name}&filters[lastName][$eq]=${lastName}`,
    ).then((draftResponse) => {
      const nominationDraftData = {
        data: {
          userId: proposerUserId,
          name,
          lastName,
          value: draftFormData,
        },
      };

      const draftResponseId = draftResponse?.data?.data?.[0]?.id;

      let apiCall;

      if (draftResponseId) {
        apiCall = axiosPut(
          `${collectionName}/${draftResponseId}`,
          nominationDraftData,
        );
      } else if (numberOfErrors > 0) {
        apiCall = axiosPost(`${collectionName}`, nominationDraftData);
      }

      if (apiCall) {
        apiCall.then((apiCallResponse) => {
          if (apiCallResponse?.data?.data) {
            setRefreshCollections(!refreshCollections);

            if (userDetails?.roles?.includes('admin')) {
              setRefreshNominations(!refreshNominations);
            }

            sessionStorage.setItem('draftId', apiCallResponse.data.data.id);

            handleNominationUpdate(
              nominationId,
              draftFormData,
              userDetails,
              refreshNominations,
              setRefreshNominations,
            );
          }
        });
      }
    });
  }
};
