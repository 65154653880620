import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import FilteringTable from './Table/FilteringTable/FilteringTable';
import ColumnFilter from './Table/FilteringTable/ColumnFilter';
import PageTitle from '../layouts/PageTitle';
import { tableColumnNamesReferee } from '../../utils/tableColumnNamesReferee';
import { arrayToFlatObject } from '../../utils/arrayToFlatObject';
import { tableRowsValuesRefereeAdministration } from '../../utils/tableRowsValuesRefereeAdministration';
import { CommonCollections } from '../../App';
import RefereeReportCell from './RefereeReportCell';
import { filteringTableGetTableColumns } from '../../utils/filteringTableGetTableColumns';
import { useStaticCollections } from '../../context/StaticCollections';
import { useRefereeCollections } from '../../context/RefereeReportCollections';
import { useLoggedInUserContextProvider } from '../../context/LoggedInUserContext';
import { useAdminActionCollections } from '../../context/AdminActionCollections';

const Cell = ({ row }) => {
  const { refereeStatusId, id } = row.original;
  const buttonIcon =
    refereeStatusId === 1 || refereeStatusId === 6 || refereeStatusId === 5
      ? 'bi bi-pen action-button'
      : 'bi bi-eye view-button';
  const buttonToolTipText =
    refereeStatusId === 1 || refereeStatusId === 6 || refereeStatusId === 5
      ? 'Edit'
      : 'View';
  const linkUrl = `/referee`;

  return (
    <Link
      to={linkUrl}
      onClick={() => {
        sessionStorage.setItem('refereeReportId', id);
      }}
    >
      <span title={buttonToolTipText}>
        <i className={buttonIcon} />
      </span>
    </Link>
  );
};

const RefereeReport = () => {
  const { userDetails } = useLoggedInUserContextProvider();
  const {
    fellows,
    declineReasons,
    refereeStatuses,
    sectionalCommitteesWithCmse,
    users,
    nonAdminNominationStatusTypes,
  } = useStaticCollections();

  const { refreshDynamicCollections, setRefereeReportId, nonAasReferees } =
    useContext(CommonCollections);
  const {
    refereeReports,
    candidates,
    nominationArray,
    nonAdminRefereeReportId,
    setNonAdminRefereeReportId,
  } = useRefereeCollections();

  const { disableRefereeReportUploads } = useAdminActionCollections();
  const [refereesData, setRefereesData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');

  useEffect(() => {
    setRefereeReportId(null);
    setNonAdminRefereeReportId(null);
  }, [setRefereeReportId, setNonAdminRefereeReportId]);

  useEffect(() => {
    if (userDetails) {
      const tableColumns = filteringTableGetTableColumns(
        tableColumnNamesReferee,
        ColumnFilter,
        RefereeReportCell,
      );

      const tableData = tableRowsValuesRefereeAdministration(
        refereeReports,
        refereeStatuses,
        declineReasons,
      );

      setTableColumn(tableColumns);
      const refereeData = [
        tableData.map((item) => item.nominationId),
        tableData,
      ];

      const nominationArr = nominationArray.map((item) => ({
        [item.id]: item.attributes,
      }));

      const nominationsObj = arrayToFlatObject(nominationArr);

      const tableDataTwo = Object.values(refereeData[1]).map((referee) => {
        if (
          nominationsObj[referee.nominationId] &&
          (nominationsObj[referee.nominationId]?.nominationStatusTypeId === 3 ||
            nominationsObj[referee.nominationId]?.nominationStatusTypeId === 2)
        ) {
          referee.candidate =
            candidates[nominationsObj[referee.nominationId].candidateId];
          referee.sectionalCommittee =
            sectionalCommitteesWithCmse[
              nominationsObj[referee.nominationId]?.sectionalCommitteeId ?? 14
            ];
          referee.refereeUser = referee.refereeFellowId
            ? fellows[referee.refereeFellowId]
            : nonAasReferees[referee.nonAasRefereeId];
        }

        return referee;
      });

      const filteredTableDataTwo = tableDataTwo.filter(
        (referee) => referee.candidate,
      );

      filteredTableDataTwo.sort(
        (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt),
      );

      if (disableRefereeReportUploads) {
        setNotificationMessage(
          'Referee report submissions are now closed. If you are required to submit a report, please contact fellowship@science.org.au.',
        );
        setNotificationTitle('Referee report submissions are now closed');
      }

      setRefereesData(filteredTableDataTwo);
      setRefereeReportId(null);
      setNonAdminRefereeReportId(null);
    }
  }, [
    refereeStatuses,
    sectionalCommitteesWithCmse,
    users,
    declineReasons,
    fellows,
    candidates,
    nominationArray,
    refereeReports,
    refreshDynamicCollections,
    setRefereeReportId,
    userDetails,
    nonAdminNominationStatusTypes,
    nonAasReferees,
    nonAdminRefereeReportId,
    setNonAdminRefereeReportId,
    disableRefereeReportUploads,
  ]);

  const resetNotification = useCallback(() => {
    setNotificationTitle(``);
    setNotificationMessage('');
  }, []);

  useEffect(() => {
    if (!disableRefereeReportUploads) {
      resetNotification();
    }
  }, [disableRefereeReportUploads, resetNotification]);

  return (
    <>
      <PageTitle
        activeMenu="My References"
        motherMenu="Home"
        notificationMessage={notificationMessage}
        notificationTitle={notificationTitle}
      />
      <FilteringTable
        componentName="RefereeReport"
        columns={tableColumn}
        data={refereesData}
        markDownName="myReferences"
      />
    </>
  );
};

Cell.propTypes = {
  row: PropTypes.object,
};

export default RefereeReport;
