import { useEffect } from 'react';

const useFilterInstance = (filterInstance, tableInstance, componentName) => {
  useEffect(() => {
    const doesColumnExist = (columnName) =>
      tableInstance?.getAllLeafColumns?.().some((col) => col.id === columnName);

    const restoreTemporaryFilters = () => {
      const temporaryFilters = JSON.parse(
        localStorage.getItem(`${componentName}TemporaryFilters`) || '[]',
      );

      if (Array.isArray(temporaryFilters) && temporaryFilters.length > 0) {
        tableInstance.setColumnFilters(
          temporaryFilters.filter(({ id }) => doesColumnExist(id)),
        );
      }
    };

    if (filterInstance?.columnName && filterInstance?.filterValue) {
      const currentFilters = tableInstance.getState().columnFilters || [];

      localStorage.setItem(
        `${componentName}TemporaryFilters`,
        JSON.stringify(currentFilters),
      );

      tableInstance.resetColumnFilters();

      if (doesColumnExist(filterInstance.columnName)) {
        tableInstance.setColumnFilters([
          {
            id: filterInstance.columnName,
            value: filterInstance.filterValue,
          },
        ]);
      }
    } else {
      restoreTemporaryFilters();

      if (
        filterInstance?.columnName &&
        doesColumnExist(filterInstance.columnName)
      ) {
        const currentFilters = tableInstance.getState().columnFilters || [];
        const updatedFilters = currentFilters.filter(
          (filter) => filter.id !== filterInstance.columnName,
        );
        tableInstance.setColumnFilters(updatedFilters);
      }
    }
  }, [filterInstance, tableInstance, componentName]);
};

export default useFilterInstance;
