import React, { Fragment, useContext, useEffect, useState } from 'react';
import Select from 'react-select';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';

import { fetchData } from '../../../../utils/fetchData';

import { Context } from '../Wizard/WizardWrapper';

const SelectElement = ({
  name,
  options,
  getQueryFilters,
  setState,
  isMulti = false,
  disabled = false,
}) => {
  const { register, setValue, watch, control, formState } = useContext(Context);
  const [currentOptions, setCurrentOptions] = useState([]);

  useEffect(() => {
    let filterQueryString =
      getQueryFilters !== undefined ? getQueryFilters(watch(), name) : '';

    if (options === 'organisations') {
      filterQueryString = '?sort=name';
    }

    if (options === 'non-aas-referees') {
      filterQueryString = '?sort=lastName&pagination[limit]=6000';
    }

    if (options === 'fellows') {
      const baseFellowFilterQueryString =
        '?filters[active][$eq]=true&filters[doNotContact][$eq]=false&sort=lastName';

      const fieldNames = [
        'proposerFellowId',
        'seconderFellowId',
        'supporterOneFellowId',
        'supporterTwoFellowId',
        'supporterThreeFellowId',
        'supporterFourFellowId',
      ];

      filterQueryString = fieldNames.includes(name)
        ? `${baseFellowFilterQueryString}&filters[isCorrespondingMember][$eq]=false`
        : baseFellowFilterQueryString;
    }

    if (options === 'non-aas-referees') {
      filterQueryString =
        '?sort=lastName&pagination[limit]=6000&filters[isActive][$eq]=true';
    }

    fetchData(`${options}${filterQueryString}`, setCurrentOptions);
  }, [name, formState, getQueryFilters, options, watch]);

  const handleChange = (event, fieldName) => {
    const eventTargetName =
      event.target !== undefined ? event.target.name : fieldName;

    const eventTargetValue =
      event.target !== undefined ? parseInt(event.target.value) : event;

    if (setState !== undefined) {
      setState(currentOptions, eventTargetName, eventTargetValue);
    }

    setValue(eventTargetName, eventTargetValue);
  };

  if (
    options === 'fellows' ||
    options === 'non-aas-referees' ||
    (currentOptions.length !== 0 && currentOptions.length > 20) ||
    isMulti
  ) {
    return (
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          const objectKeyName =
            options === 'fellows' || options === 'non-aas-referees'
              ? 'label'
              : 'name';

          const selectOptions = currentOptions
            ? currentOptions.map((item) => ({
                value: item.id,
                label: item.attributes[objectKeyName],
              }))
            : [];

          const fnsUserFieldNames = JSON.parse(
            localStorage.getItem('fnsUserFieldNames'),
          );

          // Array of independent assessor fields to check
          const independentAssessorFields = [
            'refereeSevenFellowId',
            'refereeEightFellowId',
            'refereeNineFellowId',
          ];

          const nonAasRefereeFieldNames = [
            'nonAasRefereeOneId',
            'nonAasRefereeTwoId',
            'nonAasRefereeThreeId',
            'nonAasRefereeFourId',
            'nonAasRefereeFiveId',
            'nonAasRefereeSixId',
            'nonAasRefereeSevenId',
            'nonAasRefereeEightId',
            'nonAasRefereeNineId',
          ];

          // Add independent assessor fields to fnsUserFieldNames if they are watched
          independentAssessorFields.forEach((item) => {
            if (watch(item) && !fnsUserFieldNames.includes(item)) {
              fnsUserFieldNames.push(item);
            }
          });

          const organisationOptions = [
            ...selectOptions,
            { value: 0, label: '-- My organisation is not listed --' },
          ];

          const nonAasRefereeOptions = [
            ...selectOptions,
            { value: 0, label: '-- My non AAS referee is not listed --' },
          ];

          const filteredArray = fnsUserFieldNames
            ? fnsUserFieldNames
                .filter((item) => watch(item) && watch(item) !== '')

                .map(
                  (item) =>
                    watch(item).value !== undefined && watch(item).value,
                )
            : [];

          const filteredArrayTwo = nonAasRefereeFieldNames
            ? nonAasRefereeFieldNames
                .filter(
                  (item) =>
                    watch(item) &&
                    watch(item) !== '' &&
                    watch(item)?.value !== 0,
                )
                .map((item) => watch(item).value && watch(item).value)
            : [];

          let fellowsOptions = selectOptions.filter(
            (item) => !filteredArray.includes(item.value),
          );

          const nonAasRefereeFilteredOptions = nonAasRefereeOptions.filter(
            (item) => !filteredArrayTwo.includes(item.value),
          );

          const existingFellows = watch('existingFellows');
          if (existingFellows && existingFellows.length > 0) {
            fellowsOptions = fellowsOptions.filter(
              (item) => !existingFellows.includes(item.value),
            );
          }

          let optionsArray = selectOptions;

          if (options === 'fellows') {
            optionsArray = fellowsOptions;
          }

          if (options === 'organisations') {
            optionsArray = organisationOptions;
          }

          if (options === 'non-aas-referees') {
            optionsArray = nonAasRefereeFilteredOptions;
          }

          return (
            <Select
              name={name}
              onChange={(e) => {
                field.onChange(e);
                // custom change event
                setValue(name, e, { shouldValidate: true });
                handleChange(e, name);
              }}
              value={watch(name)}
              options={optionsArray}
              isMulti={isMulti}
              styles={{
                control: (baseStyles) => ({
                  ...baseStyles,
                  height: `${isMulti ? '' : '3rem'}`,
                  borderRadius: '0.5rem',
                  borderColor: '#B3B3B3',
                }),
              }}
              className={`mb-3 ${name}`}
              disabled={disabled}
            />
          );
        }}
      />
    );
  }

  return (
    <select
      className="form-control mb-3 "
      name={name}
      value={watch(name)}
      {...register(name, {
        onChange: (e) => {
          // custom change event
          handleChange(e, name);
        },
      })}
      disabled={disabled}
    >
      {currentOptions.map((item, index) => (
        <Fragment key={`${name}-${index}`}>
          {index === 0 ? (
            <option value="none" defaultValue hidden>
              - Select an option -
            </option>
          ) : (
            ''
          )}

          <option
            key={item.id}
            value={options === 'binaries' ? item.id - 1 : item.id}
          >
            {item.attributes.name}
          </option>
        </Fragment>
      ))}
    </select>
  );
};

SelectElement.propTypes = {
  name: PropTypes.string,
  options: PropTypes.string,
  getQueryFilters: PropTypes.func,
  setState: PropTypes.func,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SelectElement;
