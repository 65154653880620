import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';

import ChartPie from '../charts/Chartjs/pie';
import PageTitle from '../../layouts/PageTitle';
import CardSummary from '../CardSummary';
import ComponentCardHolder from '../ColumnCardHolder';
import BarCategories from '../BarCategories';

import { NominationOrdinaryElectedContext } from '../../../context/NominationElectedProvider';

const calculatePercentage = (total, number) =>
  total > 0 ? `${Math.round((number * 100) / total)}%` : '0%';

const getGenderNeutralColor = (index) => {
  const modernColors = [
    'rgba(0, 162, 255, 1)',
    'rgba(258, 128, 25,0.5)',
    'rgba(153, 218, 255, 0.7)',
    'rgba(0, 0, 0, 0.07)',
  ];
  return (
    modernColors[index] ||
    `rgba(${Math.floor(Math.random() * 156) + 100}, ${Math.floor(Math.random() * 156) + 100}, ${Math.floor(Math.random() * 156) + 100}, 1)`
  );
};

const ElectedCandidates = () => {
  const {
    nominationsElected = 0,
    nominationsElectedFemale = 0,
    nominationsElectedMan = 0,
    nominationsElectedPrefernottoanswer = 0,
    nominationsElectedWoman = 0,
    nominationsElectedByCommittee = {},
    nominationsElectedByCommitteeGender = {},
  } = useContext(NominationOrdinaryElectedContext);

  const committeeIds = Object.keys(nominationsElectedByCommittee);
  const committeeLabels = committeeIds.map((id) => `Committee ${id}`);
  const committeeData = committeeIds.map(
    (id) => nominationsElectedByCommittee[id],
  );

  const allGenders = new Set();
  committeeIds.forEach((id) => {
    if (nominationsElectedByCommitteeGender[id]) {
      Object.keys(nominationsElectedByCommitteeGender[id]).forEach((gender) => {
        allGenders.add(gender);
      });
    }
  });

  const genderCategories = Array.from(allGenders);

  const genderDataSets = genderCategories.map((gender, index) => ({
    label: gender,
    data: committeeIds.map(
      (id) => nominationsElectedByCommitteeGender[id]?.[gender] || 0,
    ),
    color: getGenderNeutralColor(index),
  }));

  let genderChartComponent;
  if (genderDataSets.length === 0) {
    genderChartComponent = <p>No data available</p>;
  } else if (genderDataSets.length === 1) {
    genderChartComponent = (
      <BarCategories
        genderDataSets={[genderDataSets[0]]}
        labels={committeeLabels}
      />
    );
  } else {
    genderChartComponent = (
      <BarCategories genderDataSets={genderDataSets} labels={committeeLabels} />
    );
  }

  return (
    <div className="h-80">
      <PageTitle motherMenu="Elected Ordinary Nominations" activeMenu="All" />
      <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6" />
      <Row>
        <CardSummary
          title="Elected Candidates"
          progressBarNumber={nominationsElected}
          description={`Total: ${nominationsElected}`}
          numberOfRows={6}
        />
        <CardSummary
          title="Female Elected Candidates"
          spanText={calculatePercentage(
            nominationsElected,
            nominationsElectedFemale,
          )}
          progressBarNumber={nominationsElectedFemale}
          totalNumber={nominationsElected}
          description={`Total: ${nominationsElected}`}
          numberOfRows={6}
        />
        <ComponentCardHolder
          title="Elected Candidates by Gender"
          component={
            <ChartPie
              pieData={[
                nominationsElectedMan,
                nominationsElectedWoman,
                nominationsElectedPrefernottoanswer,
              ]}
              labels={['Male', 'Female', 'Prefer not to answer']}
            />
          }
          numberOfRows={12}
        />
        <ComponentCardHolder
          title="Sectional Committee"
          component={
            <BarCategories
              genderDataSets={[
                {
                  label: 'Total',
                  data: committeeData,
                  color: 'rgba(79, 129, 189, 1)',
                },
              ]}
              labels={committeeLabels}
            />
          }
          numberOfRows={12}
        />
        <ComponentCardHolder
          title="Sectional Committee - Gender"
          component={genderChartComponent}
          numberOfRows={12}
        />
      </Row>
    </div>
  );
};

export default ElectedCandidates;
