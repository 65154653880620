import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import { axiosGet } from '../utils/axiosGet';
import { useLoggedInUserContextProvider } from './LoggedInUserContext';

const AdminActionCollections = createContext();

export const AdminActionCollectionsProvider = ({ children }) => {
  const { userDetails } = useLoggedInUserContextProvider();

  const [disableNewSubmission, setDisableNewSubmission] = useState(false);
  const [disableSubmissionEdit, setDisableSubmissionEdit] = useState(false);
  const [disableRefereeReportUploads, setDisableRefereeReportUploads] =
    useState(false);
  const [disableCommitteeAccess, setDisableCommitteeAccess] = useState(false);
  useEffect(() => {
    if (userDetails) {
      const checkAdminAction = async () => {
        axiosGet('admin-action').then((response) => {
          if (response && response?.data?.data?.attributes) {
            setDisableNewSubmission(
              response?.data?.data?.attributes?.disableNewSubmission,
            );
            setDisableSubmissionEdit(
              response?.data?.data?.attributes?.disableSubmissionEdit,
            );
            setDisableRefereeReportUploads(
              response?.data?.data?.attributes?.disableRefereeReportUploads,
            );
            setDisableCommitteeAccess(
              response?.data?.data?.attributes?.disableCommitteeAccess,
            );
          }
        });
      };
      checkAdminAction();

      const fetchAdminActionInterval = async () => {
        try {
          const response = await axiosGet('admin-action');
          if (response?.data?.data?.attributes?.refreshInterval) {
            const interval = setInterval(
              checkAdminAction,
              response.data.data.attributes.refreshInterval * 60 * 1000,
            );

            return () => clearInterval(interval);
          }
        } catch (error) {
          console.error('Error fetching refresh interval:', error);
        }
      };

      fetchAdminActionInterval();
    }
  }, [userDetails]);

  return (
    <AdminActionCollections.Provider
      value={useMemo(
        () => ({
          disableNewSubmission,
          disableSubmissionEdit,
          disableRefereeReportUploads,
          disableCommitteeAccess,
        }),
        [
          disableNewSubmission,
          disableSubmissionEdit,
          disableRefereeReportUploads,
          disableCommitteeAccess,
        ],
      )}
    >
      {children}
    </AdminActionCollections.Provider>
  );
};

AdminActionCollectionsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useAdminActionCollections = () =>
  useContext(AdminActionCollections);
