import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';

import PageTitle from '../../layouts/PageTitle';
import CardSummary from '../CardSummary';
import { NominationCmSeContext } from '../../../context/NominationCmSeProvider';

const calculatePercentage = (total, number) =>
  total > 0 ? `${Math.round((number * 100) / total)}%` : '0%';

const CorrespondingSpecialElections = () => {
  const {
    nominationCmAndSe = 0,
    nominationShortlistedCmAndSe = 0,
    nominationCmAndSeFemale = 0,
  } = useContext(NominationCmSeContext);

  return (
    <div className="h-80">
      <PageTitle
        motherMenu="Corresponding Membership and Special Election"
        activeMenu="Report 2025"
      />
      <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6" />
      <Row>
        <CardSummary
          title="Total Corresponding Membership and Special Election"
          progressBarNumber={nominationCmAndSe}
          description={`Total: ${nominationCmAndSe}`}
          numberOfRows={6}
        />
        <CardSummary
          title="Shortlisted Corresponding Membership and Special Election"
          progressBarNumber={nominationShortlistedCmAndSe}
          description={`Total: ${nominationShortlistedCmAndSe}`}
          numberOfRows={6}
        />
        <CardSummary
          title="Female Corresponding Membership and Special Election"
          spanText={calculatePercentage(
            nominationCmAndSe,
            nominationCmAndSeFemale,
          )}
          progressBarNumber={nominationCmAndSeFemale}
          totalNumber={nominationCmAndSe}
          description={`Total: ${nominationCmAndSeFemale}`}
          numberOfRows={6}
        />
      </Row>
    </div>
  );
};

export default CorrespondingSpecialElections;
