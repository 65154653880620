import React, { useContext } from 'react';
import { Row } from 'react-bootstrap';

import PageTitle from '../../layouts/PageTitle';
import ChartPie from '../charts/Chartjs/pie';

import CardSummary from '../CardSummary';
import ApexBarThree from '../ApexBarThree';
import ComponentCardHolder from '../ColumnCardHolder';
import { ReportDataContext } from '../../../context/ReportDataContext';

import { getNominationRoundYear } from '../../../utils/getNominationRoundYear';
import ApexBar2 from '../charts/apexcharts/Bar2';
import ApexBar3 from '../charts/apexcharts/Bar3';

const calculatePercentage = (total, number) =>
  `${Math.round((number * 100) / total)}%`;

const NominationReports = () => {
  const data = useContext(ReportDataContext);
  const roundYear = getNominationRoundYear();

  const {
    nominationsContinuing = 0,
    nominationsNew = 0,
    nominations = 0,
    nominationsContinuingNotSubmitted = 0,
    nominationsContinuingWithdrawn = 0,
    nominationsMale = 0,
    nominationsFemale = 0,
    nominationsFemaleNew = 0,
    nominationsPreferNotToAnswer = 0,
  } = data;

  const lastRoundNominations = 300;

  return (
    <div className="h-80">
      <PageTitle motherMenu="Nominations" activeMenu={`Report ${roundYear}`} />
      <div className="col-xl-3 col-xxl-4 col-lg-6 col-sm-6" />
      <Row>
        <CardSummary
          title="Total nominations"
          progressBarNumber={nominations}
          description={`${calculatePercentage(nominations, lastRoundNominations)} from 2024`}
        />
        <CardSummary
          title="Continuing nominations"
          spanText={calculatePercentage(nominations, nominationsContinuing)}
          progressBarNumber={nominationsContinuing}
          totalNumber={nominations}
        />
        <CardSummary
          title="New nominations"
          spanText={calculatePercentage(nominations, nominationsNew)}
          progressBarNumber={nominationsNew}
          totalNumber={nominations}
        />
        <CardSummary
          title="Female - Candidates nominated"
          spanText={calculatePercentage(nominations, nominationsFemale)}
          progressBarNumber={nominationsFemale}
          totalNumber={nominations}
          barColorClass="bg-primary-light"
          numberOfRows={6}
        />
        <CardSummary
          title="Female - New nominations"
          spanText={calculatePercentage(nominations, nominationsFemaleNew)}
          progressBarNumber={nominationsFemaleNew}
          totalNumber={nominations}
          description={`${calculatePercentage(7, 10)} from 2024`}
          barColorClass="bg-primary-light"
          numberOfRows={6}
        />
        <CardSummary
          title="Continuing nominations not submitted"
          spanText={calculatePercentage(
            nominationsContinuing,
            nominationsContinuingNotSubmitted,
          )}
          progressBarNumber={nominationsContinuingNotSubmitted}
          totalNumber={nominationsContinuing}
          barColorClass="bg-primary-dark"
          numberOfRows={6}
        />
        <CardSummary
          title="Withdrawn continuing nominations"
          spanText={calculatePercentage(
            nominationsContinuing,
            nominationsContinuingWithdrawn,
          )}
          progressBarNumber={nominationsContinuingWithdrawn}
          totalNumber={nominationsContinuing}
          barColorClass="bg-primary-dark"
          numberOfRows={6}
        />
        <ComponentCardHolder
          title="Nominations"
          component={
            <ApexBar2
              title="Nominations"
              colors={['#0071b3']}
              xValues={[
                78,
                98,
                90,
                130,
                110,
                100,
                140,
                130,
                120,
                193,
                nominations,
              ]}
              xLabels={[
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022,
                2023,
                2024,
                roundYear,
              ]}
            />
          }
        />
        <ComponentCardHolder title="Recent years" component={<ApexBar3 />} />
        <ComponentCardHolder
          title="Total nominations by gender"
          component={
            <ChartPie
              pieData={[
                nominationsMale,
                nominationsFemale,
                nominationsPreferNotToAnswer,
              ]}
              labels={['Male', 'Female', 'Unanswered']}
            />
          }
          numberOfRows={12}
        />
        <ComponentCardHolder
          title="Total nominations - Female candidates"
          component={
            <ApexBarThree
              values={[57, 55, 60, 52, nominationsFemale]}
              xLabels={['2021', '2022', '2023', '2024', roundYear]}
            />
          }
        />
        <ComponentCardHolder
          title="New nominations - Female candidates"
          component={
            <ApexBarThree
              values={[15, 20, 27, 10, nominationsFemaleNew]}
              xLabels={['2021', '2022', '2023', '2024', roundYear]}
            />
          }
        />
      </Row>
    </div>
  );
};

export default NominationReports;
