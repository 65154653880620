import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { axiosGet } from '../utils/axiosGet';

export const NominationOrdinaryContext = createContext();

export const NominationOrdinaryProvider = ({ children }) => {
  const [nominationOrdinary, setNominationOrdinary] = useState({
    nominationsShortlisted: 0,
    nominationsShortlistedFemale: 0,
    nominationsShortlistedMan: 0,
    nominationsShortlistedPrefernottoanswer: 0,
    nominationsShortlistedWoman: 0,
    nominationsShortlistedByCommittee: {},
    nominationsShortlistedByCommitteeGender: {},
  });

  useEffect(() => {
    const fetchNominationOrdinary = async () => {
      try {
        const response = await axiosGet('nomination-ordinary');

        if (response?.data) {
          const { data } = response;
          const nominationsShortlistedByCommittee = {};
          const nominationsShortlistedByCommitteeGender = {};

          Object.keys(data).forEach((key) => {
            if (key.startsWith('nominationsShortlistedByCommittee')) {
              const match = key.match(/(\d+)([A-Za-z]*)$/);

              if (match) {
                const [_, committeeId, gender] = match;

                if (!gender) {
                  nominationsShortlistedByCommittee[committeeId] = data[key];
                } else {
                  if (!nominationsShortlistedByCommitteeGender[committeeId]) {
                    nominationsShortlistedByCommitteeGender[committeeId] = {};
                  }
                  nominationsShortlistedByCommitteeGender[committeeId][gender] =
                    data[key];
                }
              }
            }
          });

          setNominationOrdinary((prev) => ({
            ...prev,
            ...data,
            nominationsShortlistedByCommittee,
            nominationsShortlistedByCommitteeGender,
          }));
        }
      } catch (error) {
        console.error('Failed to fetch nomination ordinary data:', error);
      }
    };

    fetchNominationOrdinary();
  }, []);

  return (
    <NominationOrdinaryContext.Provider value={nominationOrdinary}>
      {children}
    </NominationOrdinaryContext.Provider>
  );
};

NominationOrdinaryProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
